import React, {Component} from 'react';
import './ItemWidget.css';
import { Row, Col, Hidden, Visible } from 'react-grid-system';
import Chip from './Chip';
import Like from '../components/Like';
import Remap from '../Remap';
import { Link } from 'react-router-dom';
import Localization from '../Localization';

export default class ItemWidget extends Component {
	render(){
		return(
			<div className="ItemWidget">
				<Hidden xs sm>
					<Row>
						<Col xs={8}>

							{
								this.props.item.seller.ID === 2 &&
								<Chip big={true} img="/img/icon/guarantee.png" type="expert">
									IloveLV
								</Chip>
							}
							{
								(this.props.item.seller.verified === 1 && this.props.item.seller.ID !== 2) &&
								<Chip big={true} img="/img/icon/guarantee.png" type="trusted">
									Trusted seller
								</Chip>
							}
							{
								(this.props.item.seller.verified === 0 && this.props.item.seller.ID !== 2) &&
								<Chip big={true} img="/img/icon/guarantee.png" type="invisible">
									Invisible
								</Chip>
							}
						</Col>
						<Col xs={4} className="desktop">
							<Like type="itm" id={this.props.item.id} />
						</Col>
					</Row>
				</Hidden>
				<Link to={'/item/'+(this.props.item.id ?? '')} style={{
					textDecoration: 'none',
					color: 'black'
				}}>
					<Row>
						<Col xs={12}>
							{/* Version 1*/}
							<div style={{
								position: 'inherit',
								overflow: 'hidden'
							}}>
								<div style={{
									maxHeight: '300px',
									height: '25vh',
									psotition: 'relative',
									overflow: 'hidden'
								}}>
										<img src={this.props.item.img} alt=""
											style={{
												position: 'absolute',
												height: '100%',
												left: '50%',
												transform: 'translateX(-50%)'
											}}
										/>
								</div>
							</div>
							{/* Version 2*/}
							{/*
							<Col xs={12}>
								<div style={{
									maxHeight: '300px',
									height: '25vh',
									verticalAlign: 'middle', 
									textAlign:'center'
								}}>
									<img src={this.props.item.img} alt=""
										style={{
											maxWidth: '100%',
											maxHeight: '100%',
											marginTop: '50%',
											transform: 'translateY(-50%)'
										}}
									/>
								</div>
							</Col>
							*/ }
						</Col>
					</Row>
				</Link>
				<Link to={'/item/'+(this.props.item.id ?? '')} style={{
					textDecoration: 'none',
					color: 'black'
				}}>
					<Row>
						<Col xs={12}>
							<Visible xs sm>
								<div className="mobile">
									<Like type="itm" id={this.props.item.id} />
								</div>
							</Visible>
							<p style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}>
								<span style={{
									fontSize: '1.3em'
								}}>
									<b>{this.props.item.brand}</b>
								</span>
								<br />
								<span style={{
									
								}}>
									{this.props.item.name}
								</span>
								<br />
								{Localization.t('item_prop_size')}: {this.props.item.size}
								<br />
								
								<span style={{
									fontSize: '1.1em'
								}}>
									<b>{Remap.price(this.props.item.price)} {Remap.currency(this.props.item.currency)}</b>
								</span>
								
								<br />
								<Hidden xs sm>
									<small>
										<small>
										{
											this.props.item.directShipping ? Localization.t('shiptime_short') : Localization.t('shiptime_long')
										}
										</small>
									</small>
								</Hidden>
								<Visible xs sm>
									<small>
										<small>
											<small>
											{
												this.props.item.directShipping ? 'cca 1-3 prac. dny' : 'cca 1 týden'
											}
											</small>
										</small>
									</small>
								</Visible>
							</p>
						</Col>
					</Row>
				</Link>
			</div>
		);
	}
}