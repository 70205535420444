import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import SearchResults from '../components/SearchResults';

export default function TagPage(props){
	return(
		<div className="page homePage">
			<Container>
				<Row>
					<Col xs={12}>
						<h1>{props.match.params.tag.split(',').map(tag=>{
							return '#'+tag+" ";
						})}</h1>
					</Col>
				</Row>
			</Container>
			<div  style={{
				backgroundColor: /*'#fafafa'*/'#eee',
				paddingTop: '25px',
				paddingBottom: '25px'
			}}>
				<Container>
					<SearchResults tags={props.match.params.tag.split(',')} />
				</Container>
			</div>
		</div>
	);
}