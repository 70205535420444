import React, {Component} from 'react';
import Api from '../../Api';
import Localization from '../../Localization';

export default class EditorDanger extends Component {

	
	delete = () => {
		Api.call('/item/delete',{
			id: this.props.item.ID
		})
		.then(response => {
			window.location.href = "/editor";
		});
	}

	deleteConfirm = () => {
		if (window.confirm(Localization.t('editor_delete_confirm')))
			this.delete();
	}

	render(){
		return(
			<div style={{
				color: 'red'
			}}>
				<h2>{Localization.t('editor_delete')} ?</h2>
				<p>{Localization.t('editor_delete_confirm')}</p>

				<button className="btn" style={{
					background: '#f44336',
					border: '1px solid #f44336'
				}}
				onClick={this.deleteConfirm}
				>{Localization.t('editor_delete_yes')}</button>
			</div>
		);
	}
}