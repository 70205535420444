import React, { Component } from 'react';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import SearchResults from '../components/SearchResults';
import Api from '../Api';
import Localization from '../Localization';

export default class SearchPage extends Component{

	state = {
		name: this.props.match.params.name,
		brand: this.props.match.params.brand ?? undefined,
		category: this.props.match.params.category ?? undefined,
		material: this.props.match.params.material ?? undefined,
		color: this.props.match.params.color ?? undefined,

		orderBy: 'date',
		desc: true,
		condition: this.props.match.params.condition ?? undefined,
		directShipping: undefined,

		minPrice: undefined,
		maxPrice: undefined,
		priceFilterCurrency: 'CZK',


		brands: [],
		colors: [],
		categories: [],
		materials: [],

		mobileHide: true
	}

	oldUri = window.location.href;

	componentDidUpdate() {
		if (window.location.href !== this.oldUri)
		{
			this.oldUri = window.location.href;
			this.setState({
				name: this.props.match.params.name,
				brand: this.props.match.params.brand ?? this.state.brand,
				category: this.props.match.params.category ?? this.state.brand,
				material: this.props.match.params.material ?? this.state.material,
				color: this.props.match.params.color ?? this.state.color,
				condition: this.props.match.params.condition ?? this.state.condition
			});
		}
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value === '-' ? undefined : e.target.value
		})
	}

	handleSortingChange = e => {
		var value = e.target.value;
		value = value.split('-');
		this.setState({
			orderBy: value[0],
			desc: value[1] === 'desc'
		});
	}

	componentDidMount(){
		this.updateData();
	}

	updateData = () => {
		Api.call('/zlist/brands')
		.then(response=>{
			if (response.success === true) {
				this.setState({
					brands: response.data
				})
			}
		})

		Api.call('/zlist/colors')
		.then(response=>{
			if (response.success === true) {
				this.setState({
					colors: response.data
				})
			}
		})

		Api.call('/zlist/categories')
		.then(response=>{
			if (response.success === true) {
				this.setState({
					categories: response.data
				})
			}
		})

		Api.call('/zlist/materials')
		.then(response=>{
			if (response.success === true){
				this.setState({
					materials: response.data
				})
			}
		})
	}

	showFilters = () => {
		this.setState({
			mobileHide: !this.state.mobileHide
		});
	}

	render(){

		var ordering = this.state.orderBy + '-' + (this.state.desc ? 'desc' : 'asc');
		var optgroups = [];
		for (let index = 0; index < this.state.categories.length; index++) {
			const element = this.state.categories[index];
			if (!optgroups.includes(element.group_name))
				optgroups.push(element.group_name);
		}

		return(
			<div className="page searchPage">
				
				<div  style={{
					backgroundColor: /*'#fafafa'*/'#eee',
					paddingTop: '25px',
					paddingBottom: '25px'
				}}>
					<Container>
						<Visible xs sm>
							<Row>
								<Col>
									<button onClick={this.showFilters} className="btn">{this.state.mobileHide ? 'Upravit' : 'Skrýt'} filtry vyhledávání</button>
								</Col>
							</Row>
						</Visible>
						<Row>
							<Hidden xs={this.state.mobileHide} sm={this.state.mobileHide}>
								<Col xs={12} md={3}>
									<div style={{
										background: 'white',
										padding: '20px',
										boxShadow: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
										marginBottom: '10px',
										position: 'sticky',
										top: '10em'
									}}>
										<h3 style={{
											margin: '0px'
										}}>{Localization.t('search_filter')}</h3>

										<br />

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_sort')}:</label>
										<select value={ordering} onChange={this.handleSortingChange}>
											<option value="date-desc">{Localization.t('search_filter_sort_date_desc')}</option>
											<option value="xprice-desc">{Localization.t('search_filter_sort_xprice_desc')}</option>
											<option value="xprice-asc">{Localization.t('search_filter_sort_xprice_asc')}</option>
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_shipping')}:</label>
										<select name="directShipping" value={this.state.directShipping} onChange={this.handleChange}>
											<option value="-">{Localization.t('search_filter_shipping_all')}</option>
											<option value={1}>{Localization.t('search_filter_shipping_direct')}</option>		
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_brand')}:</label>
										<select name="brand" value={this.state.brand} onChange={this.handleChange}>
											<option value="-">{Localization.t('search_filter_brand_all')}</option>
											{
												this.state.brands.map(brand=>(
													<option value={brand.ID}>{brand.name}</option>
												))
											}
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_category')}:</label>
										<select name="category" value={this.state.category} onChange={this.handleChange}>
										{
												// eslint-disable-next-line
												this.state.category !== undefined && this.state.categories.filter(x=>x.ID == this.state.category).length < 1 && 
												<option style={{display: 'none'}} value={this.state.category}>{this.state.category}</option>
											}
											<option value="-">{Localization.t('search_filter_category_all')}</option>
											{
												optgroups.map(group=>(
													<optgroup label={group ?? '-'}>
														{
															(this.state.categories ?? []).filter(x=>x.group_name === group).map(category => (
																<option value={category.ID}>{category.name}</option>
															))
														}
													</optgroup>
												))
											}
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_material')}:</label>
										<select name="material" value={this.state.material} onChange={this.handleChange}>
											<option value="-">{Localization.t('search_filter_material_all')}</option>
											{
												this.state.materials.map(material=>(
													<option value={material.ID}>{material.name}</option>
												))
											}
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_color')}:</label>
										<select name="color" value={this.state.color} onChange={this.handleChange}>
											<option value="-">{Localization.t('search_filter_color_all')}</option>
											{
												this.state.colors.map(color=>(
													<option value={color.ID}>{color.name}</option>
												))
											}
										</select>

										<label style={{marginLeft: '5px'}}>{Localization.t('search_filter_state')}:</label>
										<select name="condition" value={this.state.condition} onChange={this.handleChange}>
											<option value="-">{Localization.t('search_filter_state_all')}</option>
											<option value={1}>{Localization.t('item_prop_state_1')}</option>
											<option value={2}>{Localization.t('item_prop_state_2')}</option>
											<option value={3}>{Localization.t('item_prop_state_3')}</option>
											<option value={4}>{Localization.t('item_prop_state_4')}</option>
											<option value={5}>{Localization.t('item_prop_state_5')}</option>											
										</select>
									</div>
								</Col>
							</Hidden>
							<Col xs={12} md={9}>
								<SearchResults directShipping={this.state.directShipping} condition={this.state.condition} material={this.state.material} name={this.state.name} brand={this.state.brand} color={this.state.color} orderBy={this.state.orderBy} desc={this.state.desc} small={true} category={this.state.category} />
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}