import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import Checkbox from '../layout/Checkbox';
import Api from '../Api';
import { Row, Col } from 'react-grid-system';
import ReCAPTCHA from 'react-google-recaptcha';
import Localization from '../Localization';

const recaptchaRef = React.createRef();

export default class LoginForm extends Component {

	state = {
		email:"",
		pass: "",
		remember: true,
		error: null,
		recaptcha: ''
	}

	handleChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
		});
	}

	login = (e) => {
		e.preventDefault();
		Api.call('/session/login',{
			email: this.state.email,
			pass: this.state.pass,
			recaptcha: this.state.recaptcha,
			remember: this.state.remember
		})
		.then(response=>{
			if (response.success === true)
			{
				/* Set token */
				Api._token = response.data.token;
				/* Set cookies */
				const cookies = new Cookies();
				if (this.state.remember === true){
					var CurrentDate = new Date();
					CurrentDate.setMonth(CurrentDate.getMonth() + 1);
					cookies.set('token',Api._token,{path:'/',expires:CurrentDate});
				}
				else
					cookies.set('token',Api._token,{path:'/'});
				/* Callback */
				this.props.onLogin(response.data.user);
				window.location.reload();
			}
			else if (response.status === 'recaptcha')
			{
				this.setState({
					error: Localization.t('login_error_captcha')
				})
			}
			else if (response.status === "Wrong email or password")
			{
				this.setState({
					error: Localization.t('login_error_pass')
				});
			}
			else if (response.code === 401)
			{
				this.setState({
					error: Localization.t('login_error_verify')
				})
			}
			else
			{
				this.setState({
					error: Localization.t('login_error')
				});
			}
			recaptchaRef.current.reset();
		})
		.catch(error=>{
			this.setState({
				error: Localization.t('login_error')
			});
		})
	}

	register = (e) => {
		e.preventDefault();
		Api.registerPointer();
		Api.loginPointer();
	}

	render(){
		return(
			<div>
				<h1>{Localization.t('login_title')}</h1>
				{
					this.state.error !== null &&
					<span className="error" style={{
						background: '#F44336',
						color: 'white',
						width: '100%',
						display: 'block',
						padding: '5px'
					}}>
						{this.state.error}
					</span>
				}
				<form onSubmit={this.login}>
					<input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder={Localization.t('email')} required />
					<input type="password" name="pass" value={this.state.pass} onChange={this.handleChange} placeholder={Localization.t('password')} required />

					<Checkbox text={Localization.t('login_remember')} type="checkbox" name="remember" value={this.state.remember} onChange={this.handleChange} />

					<div style={{margin: 'auto', display:'block', width: 'fit-content'}}>
						<ReCAPTCHA ref={recaptchaRef} sitekey={Api.captchaToken} onChange={response=>{
							this.setState({
								recaptcha: response
							})
						}}
						/>
					</div>

					<Row>
						<Col xs={6}>
							<button type="button" className="btn btn-second" onClick={this.register}>{Localization.t('register_title')}</button>
						</Col>
						<Col xs={6}>
							<input type="submit" value={Localization.t('login_title')} />
						</Col>
					</Row>

					<a className="fb-login hoverZoomIn" href="/api/facebook/login">
						{Localization.t('login_via_facebook')}
					</a>
				</form>
			</div>
			
		);
	}
}