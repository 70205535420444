import React, {Component} from 'react';
import './Footer.css';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import Api from '../Api';
import Localization from '../Localization';

export default class Footer extends Component {

	state = {
		pageList: []
	}

	componentDidMount(){
		this.updatePageList();
	}

	updatePageList = () => {
		Api.call('/campaign/pagelist')
		.then(response=>{
			if (response.success === true){
				this.setState({
					pageList: response.data
				})
			}
		})
	}

	pageTitle = (url,alternative) => {
		var filter = this.state.pageList.filter(x=>x.url===url);
		if(filter.length > 0 && filter[0].title !== null && filter[0].title !== undefined)
		{
			return filter[0].title;
		}

		return alternative;
	}

	render(){
		return(
			<footer>
				<Container>
					<Row>
						<Col xs={12} md={4}>
							<label>ILOVELV</label>
							<Link to="/page/about">{this.pageTitle('about','O nás')}</Link>
							<Link to="/page/originality">{this.pageTitle('originality','Garance originality')}</Link>
						</Col>
						<Col xs={12} md={4}>
							<label>{Localization.t('footer_sell')}</label>
							<Link to="/page/sell-by-myself">{this.pageTitle('sell-by-myself','Prodám si sám')}</Link>
						</Col>
						<Col xs={12} md={4}>
							<label>{Localization.t('footer_follow_us')}</label>
							<a href="https://www.facebook.com/ilovelv.overovanioriginality?utm_source=ilovelv&utm_medium=footer&utm_campaign=Footer%20Link" target="_blank" rel="noopener noreferrer">Facebook</a>
							<a href="https://www.instagram.com/ilovelv.cz?utm_source=ilovelv&utm_medium=footer&utm_campaign=Footer%20Link" target="_blank" rel="noopener noreferrer">Instagram</a>
						</Col>
					</Row>
					<Row>
						<Col xs={12} md={4}>
							<label>{Localization.t('footer_pay_methods')}</label>
							<Row>
								<Col xs={12} sm={7} md={6}>
									<img src="/img/pays.png" alt="Loga platebních metod" />
								</Col>
							</Row>
							
						</Col>
						<Col xs={12} md={4}>
							<label>{Localization.t('footer_shipping') }</label>
							<Row>
								<Col xs={8} sm={7} md={6}>
									<img src="/img/transport/ppl-offi.png" alt="PPL" title="PPL" />
									<img src="/img/transport/ceska-posta-offi.png" alt="Česká pošta" title="Česká pošta" />
								</Col>
							</Row>
						</Col>
						<Col xs={12} md={4}>
							<label>LEGAL</label>
							<Link to="/page/terms-and-conditions-buy">{this.pageTitle('terms-and-conditions-buy','Obchodní podmínky pro kupující')}</Link>
							<Link to="/page/terms-and-conditions-sell">{this.pageTitle('terms-and-conditions-sell','Obchodní podmínky pro prodávající')}</Link>
							<Link to="/page/edification">{this.pageTitle('edification','Poučení')}</Link>
							<Link to="/page/gdpr">{this.pageTitle('gdpr','GDPR')}</Link>
							<Link to="/contact">{this.pageTitle('contact','Kontakt')}</Link>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<small style={{color: '#aeaeae'}}>&copy;2020 - {new Date().getFullYear()} {Localization.t('footer_copyright')}</small>
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}