import React, {Component} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Api from '../../Api';
import './Editor.css';
import EditorGeneral from './EditorGeneral';
import { Link, Redirect } from 'react-router-dom';
import EditorAppearance from './EditorAppearance';
import EditorPrice from './EditorPrice';
import EditorImages from './EditorImages';
import EditorDanger from './EditorDanger';
import Localization from '../../Localization';

export default class Editor extends Component {

	state = {
		item: null,
		images: [],
		error: null,
		group: this.props.match.params.group ?? 'general',
	}

	componentDidMount(){
		this.updateItem();
	}

	handleChange = (e) => {
		/*var i = this.state.item;
		i[e.target.property] = e.target.value;
		this.setState({
			item: i
		})*/
		
		Api.call('/edit/set',{
			id: this.state.item.ID,
			property: e.target.name,
			value: e.target.value
		}).then(()=>{
			this.updateItem();
		})
	}

	updateItem = () => {
		Api.call('/edit/item',{
			id: this.props.match.params.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					error: null,
					item: response.data
				})
			} else if (response.code === 404){
				this.setState({
					error: Localization.t('error_403')
				});
			} else {
				this.setState({
					error: Localization.t('error_500')
				});
			}
		})
		.catch(()=>{
			this.setState({
				error: Localization.t('error_500')
			})
		})
	}

	publish = async () => {
		
		var errors = 0;
		if (this.state.item.name.length < 1) {
			alert(Localization.t('editor_error_missing_name'));
			errors++;
		}
		if (this.state.item.description.length < 1) {
			alert(Localization.t('editor_error_missing_desc'));
			errors++;
		}
		if (this.state.item.price < 1){
			alert(Localization.t('editor_error_missing_price'));
			errors++;
		}



		if (errors > 0)
			return;
	

		if (window.confirm(Localization.t("editor_publish_confirm")) === true)
		{
			Api.call('/edit/publish',{
				id: this.props.match.params.id
			}).then(response=>{
				if (response.code === 1)
					alert(response.status);
				this.updateItem();
			})
		}
		
	}
	unpublish = () => {
		if ((this.state.item ?? []).approved === 0 || window.confirm(Localization.t('editor_publish_revoke_confirm')))
		{
			Api.call('/edit/unpublish',{
				id: this.props.match.params.id
			}).then(()=>{
				this.updateItem();
			})
		}
		
	}

	changeGroup = (e) => {
		this.setState({
			group: e.target.id
		})
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('error_403')}</span>
				</Container>
			);
		else if (this.state.error !== null)
			return(
				<Container>
					<span className="error">{this.state.error}</span>
				</Container>
			);
		else
		{
			if ((this.state.item ?? {}).orderID !== null && (this.state.item ?? {}).transportStatus > 0)
				return(
					<Redirect to={'/order/' + this.state.item.orderID} />
				)

			return(
				<div className="editor">
					{
						this.state.error === null && this.state.item === null &&
						<div style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.7)',
							top: '0px',
							left: '0px',
							zIndex: 5
						}}>
							<p style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%,-100%)',
								fontSize: '2em',
    							fontWeight: 'bold'
							}}>{Localization.t('loading')}</p>
						</div>
					}
					<Container>
						<Row>
							<Col xs={12}>
								<h2 style={{
									textAlign: "center"
								}}>{Localization.t('editor_title')}</h2>
							</Col>
						</Row>
						<Row>
							<Col xs={4}>
								<Row className={"btn" + (this.state.group === 'general' ? ' active' : '')} id="general" onClick={this.changeGroup}>
									{Localization.t('editor_group_general')}
								</Row>
								<Row className={"btn" + (this.state.group === 'appearance' ? ' active' : '')} id="appearance" onClick={this.changeGroup}>
									{Localization.t('editor_group_appearance')}
								</Row>
								<Row className={"btn" + (this.state.group === 'images' ? ' active' : '')} id="images" onClick={this.changeGroup}>
									{Localization.t('editor_group_images')}
								</Row>
								<Row className={"btn" + (this.state.group === 'price' ? ' active' : '')} id="price" onClick={this.changeGroup}>
									{Localization.t('editor_group_price')}
								</Row>
								{/* TODO: Kategorie premium */}
								<Link to="/editor" style={{
									textDecoration: 'none',
									fontWeight: 'bold',
									color: '#f44336'
								}}>
									<Row className="btn">
										{Localization.t('editor_close')}
									</Row>
								</Link>
							</Col>
							<Col xs={8}>
								{
									(this.state.item ?? []).draft === 0 && (this.state.item ?? []).approved === 0 &&
										<span className="error" style={{
											marginBottom: '10px',
											background: '#ff9800'
										}}>
											{Localization.t('editor_statemsg_waiting')}
										</span>
										
								}
								{
									(this.state.item ?? []).draft === 0 && (this.state.item ?? []).approved === 1 && (this.state.item ?? []).orderID === null &&
										<span className="error" style={{
											marginBottom: '10px',
											background: '#4caf50'
										}}>
											{Localization.t('editor_statemsg_published')}
										</span>
										
								}
								{
									(this.state.item ?? []).orderID !== null &&
									<span className="error" style={{
										marginBottom: '10px',
										background: 'repeating-linear-gradient(45deg,#4caf50 0px, #4caf50 10px, #43a047 10px, #43a047 20px)'
									}}>
										{Localization.t('editor_statemsg_sold')}
									</span>
								}
								{
									this.state.group === 'general' &&
									<EditorGeneral item={this.state.item} handler={this.handleChange} />
								}
								{
									this.state.group === 'appearance' &&
									<EditorAppearance item={this.state.item} handler={this.handleChange} />
								}
								{
									this.state.group === 'images' &&
									<EditorImages item={this.state.item} handler={this.handleChange} id={this.props.match.params.id} />
								}
								{
									this.state.group === 'price' &&
									<EditorPrice item={this.state.item} handler={this.handleChange} />
								}
								{
									this.state.group === 'delete' &&
									<EditorDanger item={this.state.item} />
								}
								<Row>
									{/* TODO: Mazání itemů */}
									<Col xs={4}>
										{
											(this.state.item ?? []).draft === 1 && this.state.group !== 'delete' &&
											<button className="btn"
												onClick={()=>{this.setState({
													group: 'delete'
												})}}
												style={{
													background: '#f44336',
													border: '1px solid #f44336'
												}}
											>{Localization.t('editor_delete')}</button>
										}
									</Col>
									<Col xs={4} />
									<Col xs={4}>
										{
											(this.state.item ?? []).draft === 1 &&
											<button className="btn" onClick={this.publish}>{Localization.t('editor_publish')}</button>
										}
										{
											(this.state.item ?? []).draft === 0 && (this.state.item ?? []).orderID === null &&
											<button className="btn" onClick={this.unpublish}>
												{
													Localization.t('editor_publish_revoke')
												}
											</button>
										}
									</Col> 
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			);
		}
	}
}