import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import Api from '../Api';
import Localization from '../Localization';
import './Comment.css';

export default class CommentWriter extends Component {

    state = {
        user: null,
        content: "",
        locked: false
    }

    componentDidMount(){
        this.updateUser();
    }

    updateUser(){
        Api.call('/session/login')
        .then(response=>{
            if (response.success){
                this.setState({
                    user: response.data.user
                });
                
            }
        })
    }

    send = () => {

        this.setState({
            locked: true
        })

        Api.call('/comments/write',{
            type: this.props.type,
            section: this.props.section,
            content: this.state.content
        })
        .then(response=>{
            if (response.success){
                this.setState({
                    content: "",
                    locked: false
                });
                this.props.onSend();
            } else {
                this.setState({
                    locked: false
                });
                alert(Localization.t('comment_write_error'));
            }
           
        });
    }

    handeChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

	render() {		
        
        if (this.state.user === null || this.state.user === undefined)
            return(
                <div />
            );
        
		return(
            <div style={{
                margin: '0px 0px 20px 0px'
            }} >
                <Row>
                    <Col xs={1}>
                        <Link to={'/user/'+this.state.user.ID}>
                            <img src={this.state.user.img} alt="" className="full circle" />
                        </Link>
                    </Col>
                    <Col xs={11}>
                        <div className="comment-content">
                            <b>{this.state.user.name}</b><br />
                            <textarea
                                disabled={this.state.locked}
                                placeholder={Localization.t('comment_write_placeholder')}
                                onChange={this.handeChange}
                                name="content"
                                style={{
                                    width: '100%',
                                    outline: 'none',
                                    resize: 'none',
                                    height: '5em',
                                    border: 'none',
                                    fontSize: '1em',
                                }}
                                value={this.state.content}
                            />

                            {
                                this.state.content.length > 5 &&
                                <div style={{
                                    overflow: 'hidden'
                                }}>
                                    <button
                                        onClick={this.send}
                                        className="btn"
                                        style={{
                                            float: 'right',
                                            width: '10em',
                                            marginTop: '0px'
                                        }}
                                    >{Localization.t("comment_write_submit")}</button>
                                </div>
                            }                           
                        </div>
                    </Col>
                </Row>
            </div>
		);
	}
}