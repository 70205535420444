import React, {Component} from 'react';
import './Cart.css';
import Api from '../Api';
import { Row, Col, Visible } from 'react-grid-system';
import { Link } from 'react-router-dom';
import Subtotal from './Subtotal';
import Localization from '../Localization';
export default class Cart extends Component {

	state = {
		items: []
	}

	token = Api._token;

	componentDidMount(){
		this.updateCart();
	}

	componentDidUpdate(prevProps) {
		if (Api._token !== this.token || prevProps !== this.props) {
			this.token = Api._token;
			this.updateCart();
		}
	}

	removeFromCart = (id) => {
		if (window.confirm(Localization.t('cart_remove_confirm'))){
			Api.call('/cart/remove',{
				id: id
			})
			.then(()=>{
				this.updateCart();
			})
			.catch(err=>{
				this.updateCart();
				console.error(err);
			})
		}
		
	}

	updateCart = () => {
		Api.call('/cart')
		.then(response=>{
			if (response.success === true){
				this.setState({
					items: response.data
				})
			}
		});
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<span className="error">{Localization.t('error_403')}</span>
			);
		else
		return(
			<div className={"cart"+(this.props.small === true ? ' small' : '')} style={{
				padding: this.props.small !== true ? '0px' : '0px 15px'
			}}>
				{
					this.state.items.length === 0 &&
					<p>{Localization.t('cart_empty')}</p>
				}

				<Row>
					<Col xs={12} md={this.props.small !== true ? 8 : 12}>
						{
							this.state.items.map((item,key)=>(
								<Link key={key} to={"/item/"+item.ID} className="sell-item">
									<Row className="row">
										<Col xs={2} md={this.props.small !== true ? 1 : 2} style={{
											padding: '0px'
										}}>
											<img
												src={item.img}
												alt=""
												style={{
													width: '100%'
												}}
											/>
										</Col>
										<Col>
											<p>
												{item.name} - {item.brand}
											</p>
										</Col>
										<Col>
											<p style={{
												textAlign: 'right'
											}}>
												{item.price + " " + (item.currency === 'czk' ? 'Kč' : '€')}
												<span className="rem" onClick={(e)=>{e.preventDefault();this.removeFromCart(item.ID)}}>
													X
												</span>
											</p>
										</Col>
									</Row>
									<Row>
										<small>{item.directShipping ? Localization.t('shiptime_short') : Localization.t('shiptime_long')}</small>
									</Row>
								</Link>
							))
						}
					</Col>
					{
						this.state.items.length > 0 && this.props.small !== true &&
						<Col xs={12} md={4}>
							<Visible xs sm>
								<br />
							</Visible>
							<Subtotal payButton={true} user={this.props.user} />
						</Col>
					}
				</Row>
			</div>
		);
	}
}