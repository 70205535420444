import React, {Component} from 'react';
import './Cart.css';
import Api from '../Api';
import Localization from '../Localization';
export default class Subtotal extends Component {

	state = {
		items: []
	}

	token = Api._token;

	componentDidMount(){
		this.updateCart();
	}

	componentDidUpdate(prevProps) {
		if (Api._token !== this.token || prevProps !== this.props) {
			this.token = Api._token;
			this.updateCart();
		}
	}

	updateCart = () => {
		Api.call('/cart')
		.then(response=>{
			if (response.success === true){
				this.setState({
					items: response.data
				})
			}
		});
	}

	render(){
		if (this.state.items.length <= 0)
			return(
				<span>{Localization.t('loading')}</span>
			);
		else{
			var total = 0;

			var additional = [...(this.props.additional ?? [])];
			return(
				<div style={{
					background: 'white',
					boxShadow: '1px 1px 7px gray',
					width: '100%',
					padding: '10px',
					boxSizing: 'border-box'
				}}>
					<b>{Localization.t('subtotal_recalc')}</b><br />
					{
						this.state.items.map(item=>{
							var price = item.xprice[this.props.user.currency.toLowerCase()];
							total += price;
							return(
								<div>
									{item.name}
									<span style={{float: 'right'}}>{price.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {this.props.user.currency.toLowerCase() === 'czk' ? 'Kč' : '€'}</span>
								</div>
							)
						})
					}
					<hr />
					<div>
						{Localization.t("subtotal")}
						<span style={{float: 'right'}}>{total.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {this.props.user.currency.toLowerCase() === 'czk' ? 'Kč' : '€'}</span>
					</div>
					{
						additional !== null && additional !== undefined && additional.length > 0 &&
						<div>
							<hr />
							{
								(additional ?? []).map(item=>{
									total += item.price
									return(
										<div>
											{item.text}
											<span style={{float: 'right'}}>{item.price.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {this.props.user.currency.toLowerCase() === 'czk' ? 'Kč' : '€'}</span>
										</div>
									)}
								)
							}
							<hr />
							<b>
							{Localization.t("subtotal_total")}
								<span style={{float: 'right'}}>{total.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {this.props.user.currency.toLowerCase() === 'czk' ? 'Kč' : '€'}</span>
							</b>
						</div>
					}

					{
						this.props.payButton === true &&
						<button className="btn" to="/checkout" onClick={()=>{window.location = "/checkout";}}>{Localization.t('subtotal_checkout')}</button>
					}
				</div>
			);
		}
	}
}