import React, {Component} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import ReCAPTCHA from 'react-google-recaptcha';
import Api from '../Api';
import StaticPageParser from '../components/StaticPageParser';
import Localization from '../Localization';

export default class ContactPage extends Component {

	state = {
		name: '',
		email: '',
		phone: '',
		subject: '',
		body: '',
		recaptcha: '',

		staticPage: null
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	submit = (e) => {
		e.preventDefault();

		if (this.state.recaptcha !== ''){
			Api.call('/feedback',this.state)
			.then(response=>{
				if (response.success === true){
					alert(Localization.t('contact_success'));
					window.gtag('event', 'conversion', {'send_to': 'AW-969069533/wTl4CLua1_kBEN2ni84D'});
					window.location.reload();
				} else {
					alert(Localization.t('contact_error'));
				}
			});
		}
	}
	

	componentDidMount(){
		window.scrollTo(0,0);
		this.updateData();
	}

	updateData = () => {
		Api.call('/campaign/page',{
			url: 'contact'
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					staticPage: response.data
				});
				window.scrollTo(0,0);
			}
		})
	}

	render(){
		return(
			<Container>
				<h1>{Localization.t('contact_title')}</h1>
				<Row>
					<Col xs={12} md={6}>
						<h2>{Localization.t('contact_form')}</h2>
						<form onSubmit={this.submit}>
							<label>{Localization.t('contact_sender')}</label>
							{
								(this.props.user === null || this.props.user === undefined) &&
								<div>
									<input type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder={Localization.t('fname') + ' ' + Localization.t('and') + ' ' + Localization.t('lname')} required />
									<input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder={Localization.t('email')} required />
									<input maxLength={15} type="text" name="phone" value={this.state.phone} onChange={this.handleChange} placeholder={Localization.t('checkout_input_phone')} required />
								</div>
							}
							{
								this.props.user !== null && this.props.user !== undefined &&
								<div style={{
									margin: '10px',
									padding: '15px',
									border: '1px solid #eaeaea',
									borderRadius: '7px',
								}}>
									<Row>
										<Col xs={3} sm={2} md={3} lg={2}>
											 <img className="circle full" src={this.props.user.img} alt="" />
										</Col>
										<Col xs={9} sm={10} md={9} lg={10}>
											<p style={{
												fontSize: '1.4em',
												margin: '0px'
											}}>{this.props.user.name}</p>
											{this.props.user.email}
										</Col>
									</Row>
								</div>
							}
							<label>{Localization.t('contact_message')}</label>
							<input maxLength={200} type="text" name="subject" value={this.state.subject} onChange={this.handleChange} placeholder={Localization.t('contact_subject')} required />
							<textarea
								name="body"
								value={this.state.body}
								onChange={this.handleChange}
								placeholder={Localization.t('contact_message_placeholder')}
								required 
								style={{
									resize: 'none',
									height: '25rem'
								}}
							/>
							<div style={{
								display: 'block',
								margin: 'auto',
								width: '305px'
							}}>
								<ReCAPTCHA sitekey={Api.captchaToken} onChange={response=>{
									this.setState({
										recaptcha: response
									})
								}}
								/>
							</div>
							<input type="submit" value="Odeslat" />
						</form>
					</Col>
					<Col xs={12} md={6}>
						<StaticPageParser json={this.state.staticPage} />
					</Col>
				</Row>
			</Container>
		);
	}
}