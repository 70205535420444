export default class Remap{
	static price(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}

	static currency(x) {
		switch (x){
			case "czk":
				return "Kč";
			case "eur":
				return "€";
			default:
				return x.toUpperCase();
		}
	}

	static country(x) {
		return x;
	}
}