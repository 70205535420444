import React from 'react';

export default function Dropdown(props){
	return(
		<div className="dropdown-wrap">
			<div className="dropdown-content">
				{props.children}
			</div>
		</div>
	)
}