import React, {Component} from 'react';
import Api from '../Api';
import { Container, Row, Col } from 'react-grid-system';
import ShippingWidget from '../layout/ShippingWidget';
import { Link } from 'react-router-dom';
import Localization from '../Localization';

export default class OrderPage extends Component {

	state = {
		order: {},
		error: null
	}

	componentDidMount(){
		this.updateOrder();
	}

	updateOrder = () => {
		Api.call('/order',{
			id: this.props.match.params.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					order: response.data
				})
			} else {
				var err = Localization.t('order_not_found');

				if (response.code === 402)
					err = Localization.t('order_not_payed');

				this.setState({
					error: err
				})
			}
		})
		.catch(()=>{
			this.setState({
				error: Localization.t('error_500')
			})
		})
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		if (this.state.error !== null)
			return(
				<Container>
					<span className="error">{this.state.error}</span>
				</Container>
			);
		return(
			<div className="page">
				<Container>
					<h1>{Localization.t('order_prop_id')} {this.props.match.params.id}</h1>
					{
						(this.state.order.items ?? []).map(item=>(
							<Row>
								<Col xs={4} md={2}>
									<img src={item.img} alt="" className="full" />
								</Col>
								<Col xs={8} md={2}>
									<p><b>{item.brandName}</b></p>
									<p>{item.name}</p>
									<p>{item.price} {item.currency}</p>
								</Col>
								<Col>
									{
										item.my === true && item.transportStatus < 2 &&
										<div>
											<b style={{color: 'red'}}>{Localization.t('order_send_it')}</b>
											<p>
												{Localization.t('order_send_it_desc')}
											</p>
											<Link to={'/dispatch/' + item.ID}>
												<button className="btn ">
													{Localization.t('order_send_it_button')}
												</button>
											</Link>
											
										</div>
									}
									{
										(item.my !== true || item.transportStatus > 1) &&
										<div>
											<ShippingWidget status={item.transportStatus} />
											<small>{Localization.t('dispatch_prop_packID')}: i{item.ID}o{this.props.match.params.id}</small>
										</div>
									}
								</Col>
							</Row>
						))
					}
				</Container>
			</div>
		);
	}
}