import React, {Component} from 'react';
import './Like.css';
//import Api from '../Api';

export default class Like extends Component {

	/*state = {
		liked: false,
		count: 0
	}

	token = Api._token;
	mounted = false;

	onClick = () => {
		if (Api._token !== null && Api._token !== undefined){
			Api.call(this.state.liked ? '/like/revoke' : '/like/give',{
				type: this.props.type ?? 'itm',
				id: this.props.id
			})
			.then(response => {
				if (response.success === true){
					this.setState({
						liked: response.data.liked,
						count: response.data.count
					});
				}
			})
			.catch(err => {
				console.error(err);
			});


			this.setState({
				liked: !this.state.liked
			});
		} else {
			Api.loginPointer();
		}


		
	}

	componentDidMount() {
		this.mounted = true;
		this.updateComponent();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (Api._token !== this.token || prevProps !== this.props) {
			this.token = Api._token;
			this.updateComponent();
		}
	}

	updateComponent = () => {
		if (this.props.id === null || this.props.id === undefined || this.props.type === null || this.props.type === undefined)
			return;

		Api.call('/like/check',{
			type: this.props.type,
			id: this.props.id
		})
		.then(response => {
			if (response.success === true && this.mounted === true){
				this.setState({
					liked: response.data.liked,
					count: response.data.count
				});
			}
		})
		.catch(err => {
			console.error(err);
		});
	}

	render(){
		return(
			<div className="like" onClick={this.onClick}>
				<img src={this.state.liked ? '/img/icon/love.png' : '/img/icon/love-outline.png'} alt="" />
				<span className="count textVerticalCenter">
					{this.state.count > 0 ? this.state.count : ''}
				</span>
			</div>
		);
	}*/

	render(){
		return <div />
	}
}