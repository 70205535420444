import React, { Component } from 'react';

export default class CheckoutTransport extends Component {

	handleChange = () => {
		this.props.onChange({
			target: {
				name: this.props.name,
				value: this.props.value
			}
		});
	}

	render(){
		return (
			<div onClick={this.handleChange} style={{
				padding: '20px',
				textAlign: 'center',
				border: this.props.active === true ? '1px solid #a16d5d' : '1px solid transparent',
				cursor: 'pointer'
			}}>
				<img src={this.props.img} alt="" style={{
					width: '100%'
				}} />
			</div>
		);
	}
}