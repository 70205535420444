import React, {Component} from 'react';
import Api from '../../Api';
import Localization from '../../Localization';

export default class EditorGeneral extends Component {

	state = {
		brands: [],
		categories: []
	}

	componentDidMount(){
		Api.call("/zlist/brands")
		.then(response=>{
			if (response.success === true){
				this.setState({
					brands: response.data
				})
			}
		})

		Api.call("/zlist/categories")
		.then(response=>{
			if (response.success === true){
				this.setState({
					categories: response.data
				})
			}
		})
	}

	render(){
		var optgroups = [];
		for (let index = 0; index < this.state.categories.length; index++) {
			const element = this.state.categories[index];
			if (!optgroups.includes(element.group_name))
				optgroups.push(element.group_name);
		}
		return(
			<div>
				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_input_name')}</label>			
				<input type="text" placeholder={Localization.t('editor_input_name')} name="name" value={(this.props.item ?? []).name} onChange={this.props.handler} disabled={!(this.props.item ?? []).draft} />

				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_input_category')}</label>
				<select name="category" onChange={this.props.handler} disabled={!(this.props.item ?? []).draft}>
					<option disabled value="-1">{Localization.t('editor_input_category_placeholder')}</option>
					{
						optgroups.map(group=>(
							<optgroup label={group ?? '-'}>
								{
									(this.state.categories ?? []).filter(x=>x.group_name === group).map(category => (
										<option selected={category.ID === (this.props.item ?? []).category} value={category.ID}>{category.name}</option>
									))
								}
							</optgroup>
						))
					}
				</select>


				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_input_brand')}</label>
				<select name="brand" onChange={this.props.handler} disabled={!(this.props.item ?? []).draft}>
					<option disabled value="-1">{Localization.t('editor_input_brand_placeholder')}</option>
					{
						this.state.brands.map(brand=>{
							return(
								<option key={brand.ID} value={brand.ID} selected={brand.ID === (this.props.item ?? []).brand}>{brand.name}</option>
							);
						})
					}
				</select>

				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_input_description')}</label>
				<textarea name="description" onChange={this.props.handler} style={{
					width: '100%',
					resize: 'none',
					height: '300px',
					padding: '10px'
				}} value={(this.props.item ?? []).description} disabled={!(this.props.item ?? []).draft}>
				</textarea>
			</div>
		);
	}
}