import React, {Component} from 'react';
import Api from '../Api';
import { Row, Col } from 'react-grid-system';
import ReCAPTCHA from 'react-google-recaptcha';
import Checkbox from '../layout/Checkbox';
import Localization from '../Localization';

const recaptchaRef = React.createRef();
export default class RegisterForm extends Component {
	state = {
		fname: "",
		lname: "",
		name: "",
		country: "CZ",
		email: "",
		pass: "",
		passAgin: "",
		recaptcha: "",
		error: null,
		loading: false,
		gdpr: false
	}

	handleChange = (e) =>{
		this.setState({
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
		});
	}

	register = (e) => {
		e.preventDefault();

		if (this.state.loading === true)
			return;
		
		if (this.state.gdpr === false){
			this.setState({
				error: Localization.t('register_error_gdpr')
			});
			return;
		}

		if (this.state.pass !== this.state.passAgin){
			this.setState({
				error: Localization.t('register_error_passmatch')
			})
			return;
		}
		if (this.state.pass.length < 6){
			this.setState({
				error: Localization.t('register_error_shortpass')
			})
			return;
		}

		this.setState({
			loading: true
		})

		Api.call('/register',this.state)
		.then(response=>{
			if (response.success === true) {
				alert(Localization.t('register_email_send'));
			} else if (response.status === 'recaptcha') {
				this.setState({
					error: Localization.t('login_error_captcha')
				})
			} else {
				this.setState({
					error: Localization.t('login_error')
				})
			}

			this.setState({
				loading: false
			});
			recaptchaRef.current.reset();
		})
		.catch(()=>{
			this.setState({
				error: Localization.t('login_error'),
				loading: false
			})
		})
	}

	login = (e) => {
		e.preventDefault();
		Api.registerPointer();
		Api.loginPointer();
	}

	render(){
		return(
			<div>
				<h1>{Localization.t('register_title')}</h1>
				{
					this.state.error !== null &&
					<span className="error" style={{
						background: '#F44336',
						color: 'white',
						width: '100%',
						display: 'block',
						padding: '5px'
					}}>
						{this.state.error}
					</span>
				}
				{
					this.state.loading === true &&
					<span className="error" style={{
						background: '#eaeaea',
						color: 'black'
					}}>
						{Localization.t('loading')}
					</span>
				}
				<form onSubmit={this.register} style={{
					opacity: this.state.loading === true ? 0.5 : 1
				}}>
					<Row>
						<Col xs={12} md={6}>
							<input type="text" name="fname" value={this.state.fname} onChange={this.handleChange} placeholder={Localization.t('fname')} required />
						</Col>
						<Col xs={12} md={6}>
							<input type="text" name="lname" value={this.state.lname} onChange={this.handleChange} placeholder={Localization.t('lname')} required />
						</Col>
					</Row>
					<input type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder={Localization.t('username')} required />
					<input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder={Localization.t('email')} required />
					<input type="password" name="pass" value={this.state.pass} onChange={this.handleChange} placeholder={Localization.t('password')} required />
					<input type="password" name="passAgin" value={this.state.passAgin} onChange={this.handleChange} placeholder={Localization.t('password_again')} required />
					
					<Checkbox text="" type="checkbox" name="gdpr" value={this.state.remember} onChange={this.handleChange} />
					<span style={{marginLeft: '2em'}}>{Localization.t('register_agree_terms_1')} <a href="/page/terms-and-conditions-buy" target="_blank">{Localization.t('register_agree_terms_2')}</a>, <a href="/page/terms-and-conditions-sell">{Localization.t('register_agree_terms_3')}</a> {Localization.t('and')} <a href="/page/gdpr" target="_blank">{Localization.t('register_agree_terms_4')}</a></span>

					<Checkbox text="" type="checkbox" name="newslatter" />
					<span style={{marginLeft: '2em'}}>{Localization.t('register_agree_news')}</span>


					<div style={{margin: 'auto', display:'block', width: 'fit-content'}}>
						<ReCAPTCHA ref={recaptchaRef} sitekey={Api.captchaToken} onChange={response=>{
							this.setState({
								recaptcha: response
							})
						}}
						/>
					</div>
					
					<Row>
						<Col xs={6}>
							<button type="button" onClick={this.login} className="btn btn-second">{Localization.t('login_title')}</button>
						</Col>
						<Col xs={6}>
							<input type="submit" value={Localization.t('register_title')}/>
						</Col>
					</Row>

					<a className="fb-login hoverZoomIn" href="/api/facebook/login">
						{Localization.t('register_via_facebook')}
					</a>
				</form>
			</div>
			
		);
	}
}