import React, { Component } from 'react';
import { Container} from 'react-grid-system';
import Api from '../Api';
import StaticPageParser from '../components/StaticPageParser';
import Localization from '../Localization';
import NotFoudPage from './NotFound';

export default class PagePage extends Component{

	state = {
		data: null
	}

	page = this.props.match.params.page;

	componentDidMount(){
		this.updateData();
	}

	componentDidUpdate(){
		if (this.page !== this.props.match.params.page){
			this.page = this.props.match.params.page;
			this.updateData();
		}
	}

	updateData = () => {
		Api.call('/campaign/page',{
			url: this.page
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					data: response.data
				});
				window.scrollTo(0,0);
			} else {
				this.setState({
					data: '404'
				})
			}
		})
	}

	render(){
		return(
			<div className="page">
				{
					this.state.data === null &&
					<Container>
						<p>{Localization.t('loading')}</p>
					</Container>
				}
				{
					this.state.data === '404' &&
					<NotFoudPage />
				}
				{
					this.state.data !== null && this.state.data !== '404' && this.state.data !== undefined &&
					<Container>
						<StaticPageParser json={this.state.data} />
					</Container>
				}
			</div>
		);
	}
}