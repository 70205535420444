import React, { Component } from "react";
import './Search.css';
import Cookies from "universal-cookie";
import Localization from "../Localization";

export default class Search extends Component {

	state = {
		search: new Cookies().get("lastSearch") ?? ''
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	render(){
		return(
			<div className="search">
				<form onSubmit={(e)=>{
					e.preventDefault();
					new Cookies().set("lastSearch",this.state.search,{path: ''});
					window.location = "/search/"+encodeURI(this.state.search);
				}}>
					<input placeholder={`🔍 ${Localization.t('menu_search')}`} name="search" autoComplete="off" type="text" value={this.state.search} onChange={this.handleChange} />
				</form>
			</div>
		);
	}
}