import React, {Component} from 'react';
import './MobileMenu.css';
import { Row, Col } from 'react-grid-system';
import Api from '../Api';
import { Link } from 'react-router-dom';
import Localization from '../Localization';

export default class MobileMenu extends Component {

	stopClick = (e) => {
		e.stopPropagation();
	}

	render(){
		return(
			<div className="mobile-menu-wrap" onClick={this.props.close}>
				<div className="mobile-menu" onClick={this.stopClick}>
					<Row>
						<Col xs={12}>
							<img src="/img/icon/menu-close.png" alt="" className="spin" onClick={this.props.close} style={{
								float: 'right',
								width: '50px',
								height: 'auto'
							}} />
						</Col>
					</Row>
					<Row>
						<Col xs={3} />
						<Col xs={6}>
							<img src={(this.props.user ?? {img: "/img/newUser.png"}).img} alt="" className="full circle" />
						</Col>
					</Row>
					{
						(this.props.user === null ||
						this.props.user === undefined) &&
						<Row>
							<Col xs={6}>
								<button className="btn btn-second" onClick={Api.registerPointer}>{Localization.t('menu_register')}</button>
							</Col>
							<Col xs={6}>
								<button className="btn" onClick={Api.loginPointer}>{Localization.t('menu_register')}</button>
							</Col>
						</Row>
					}
					<br />
					{
						this.props.user !== null &&
						this.props.user !== undefined &&
						<Row onClick={this.props.close}>
							<Col xs={2}>
								<Link to="/editor">
									<img src="/img/icon/sell.png" alt="" className="full" />
								</Link>
							</Col>
							<Col xs={2}>
								<Link to={'/user/'+this.props.user.ID}>
									<img src="/img/icon/user.png" alt="" className="full" />
								</Link>
							</Col>
							<Col xs={2}>
								<Link to="/cart">
									<img src="/img/icon/cart.png" alt="" className="full" />
								</Link>
							</Col>
							<Col xs={2}>
								<Link to="/notify">
									<img src="/img/icon/bell.svg" alt="" className="full" />
								</Link>
							</Col>
							<Col xs={2}>
								<Link to="/settings">
									<img src="/img/icon/settings.svg" alt="" className="full" />
								</Link>
							</Col>
							<Col xs={2}>
								<img src="/img/icon/logout.png" alt="" className="full" onClick={Api.logoutPointer} />
							</Col>
						</Row>
					}
					<br />
					<div className="item">
						<Link to={'/sell'} onClick={this.props.close} style={{color: 'red'}}>
							Prodat
						</Link>
					</div>
					<div>
					{
						this.props.menu.map(item=>(
							<div className="item">
								{
									item.href !== null &&
									item.href !== undefined &&
									<Link to={item.href ?? '#'}>
										{item.text}
									</Link>
								}
								{
									(item.href === null || item.href === undefined) &&
									item.text
								}
								{
									item.sub !== null &&
									item.sub !== undefined &&
									item.sub.length > 0 &&
									<div className="arrow" />
								}
								{
									item.sub !== null &&
									item.sub !== undefined &&
									<div className="subitems">
										{
											item.sub.map(group=>(
												<div class="item">
													<b>{group.text}</b>
													{
														group.items !== null &&
														group.items !== undefined &&
														group.items.map(itm=>(
															<div className="item">
																<Link to={itm.href}>{itm.text}</Link>
															</div>
														))
													}
												</div>
											))
										}
									</div>
								}
							</div>
						))
					}
					</div>
					
				</div>
			</div>
		);
	}
}