export default class Api{

	static url =  'https://ilovelv.cz/api'; // */'http://ilovelv.localhost/api';
	static _token = null;
	static captchaToken = '6Ld3QtQZAAAAAKi21x9qhf4FvTo1oXe0TKv27lbE';

	static loginPointer = null;
	static registerPointer = null;
	static logoutPointer = null;
	static updatePointer = null;
	static fullRenderPointer = null;

	static async call(path = '',data = {}, method = 'POST'){
		path = Api.url + path;

		var request = {
			token: Api._token,
			body: data
		}

		var response = await fetch(path, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: method,
			cache: 'no-cache',
			body: JSON.stringify(request)
		});
		response = await response.json();
		console.log(response);
		if (this._token !== null && 'code' in response && response.code === 403)
			this.logoutPointer();
		else
			return response;
	}
}