import React, {Component} from 'react';
import { Container, Row, Col} from 'react-grid-system';
import Api from '../../Api';
import './Editor.css';
import { Link } from 'react-router-dom';
import Chip from '../../layout/Chip';

import './EditorAll.css';
import Localization from '../../Localization';
export default class EditorAll extends Component {

	state = {
		items: [],
		error: null
	}

	componentDidMount(){
		this.updateItems();
	}

	updateItems = () => {
		Api.call('/edit',{
			id: this.props.match.params.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					error: null,
					items: response.data
				})
			} else {
				this.setState({
					error: Localization.t('error_500')
				});
			}
		})
		.catch(()=>{
			this.setState({
				error: Localization.t('error_500')
			})
		})
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('error_403')}</span>
				</Container>
			);
		else if (this.state.error !== null)
			return(
				<Container>
					<span className="error">{this.state.error}</span>
				</Container>
			);
		else
			return(
				<Container>
					<h1>{Localization.t('editor_list_title')}</h1>
					{
						this.state.items.map(item=>{
							return(
								<Link to={"/editor/"+item.ID} className="sell-item">
									<Row className="row">
										<Col>
											<p>
												{item.name}
											</p>
										</Col>
										<Col>
											<p>
												{item.brandName}
											</p>
										</Col>
										<Col>
											<p>
												{
													item.draft === 1 && item.orderID === null &&
													<Chip>{Localization.t('editor_state_draft')}</Chip>
												}
												{
													item.draft === 0 && item.approved === 0 && item.orderID === null &&
													<Chip>{Localization.t('editor_state_waiting')}</Chip>
												}
												{
													item.draft === 0 && item.approved === 1 && item.orderID === null &&
													<Chip type="success">{Localization.t('editor_state_published')}</Chip>
												}
												{
													item.orderID !== null && item.transportStatus < 2 &&
													<Chip type="warning">{Localization.t('editor_state_send_it')}</Chip>
												}
												{
													item.orderID !== null && item.transportStatus === 2 &&
													<Chip type="loading">{Localization.t('editor_state_shipping')}</Chip>
												}
												{
													item.orderID !== null && item.transportStatus === 3 &&
													<Chip type="sold">{Localization.t('editor_state_sold')}</Chip>
												}
											</p>
										</Col>
										<Col>
											<p style={{
												textAlign: 'right'
											}}>
												{item.price + " " + item.currency}
											</p>
										</Col>
									</Row>
								</Link>
							);
						})
					}
					<Link to={"/sell"} className="sell-item">
						<Row className="row">
							<Col>
								<p style={{
									color: '#666'
								}}>
									+ {Localization.t('editor_new')}
								</p>
							</Col>
						</Row>
					</Link>
				</Container>
			);
	}
}