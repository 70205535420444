import React, {Component} from 'react';
import { Row, Col, Hidden } from 'react-grid-system';
import './ShippingWidget.css';

export default class ShippingWidget extends Component {
	render(){
		return(
			<Row>
				<Col>
					<img className="full" src={this.props.status < 1 ? '/img/seller.png' : '/img/seller_green.png'} alt="" />
				</Col>
				<Hidden xs sm>
					<Col>
						<img className="full" src={this.props.status < 2 ? '/img/arrow.png' : '/img/arrow_green.png'} alt="" />
					</Col>
				</Hidden>
				<Col>
					<img className={'full' + (this.props.status === 2 ? ' car' : ' ')} src={this.props.status < 2 ? '/img/transport.png' : '/img/transport_green.png'} alt="" />
				</Col>
				<Hidden xs sm>
					<Col>
						<img className="full" src={this.props.status < 3 ? '/img/arrow.png' : '/img/arrow_green.png'} alt="" />
					</Col>
				</Hidden>
				<Col>
					<img className="full" src={this.props.status < 3 ? '/img/ilovelv_gray.png' : '/img/ilovelv_green.png'} alt="" />
				</Col>
				<Hidden xs sm>
					<Col>
						<img className="full" src={this.props.status < 4 ? '/img/arrow.png' : '/img/arrow_green.png'} alt="" />
					</Col>
				</Hidden>
				<Col>
					<img className={'full' + (this.props.status === 4 ? ' car' : ' ')} src={this.props.status < 4 ? '/img/transport.png' : '/img/transport_green.png'} alt="" />
				</Col>
				<Hidden xs sm>
					<Col>
						<img className="full" src={this.props.status < 5 ? '/img/arrow.png' : '/img/arrow_green.png'} alt="" />
					</Col>
				</Hidden>
				<Col>
					<img className="full" src={this.props.status < 5 ? '/img/buyer.png' : '/img/buyer_green.png'} alt="" />
				</Col>
			</Row>
		);
	}
}