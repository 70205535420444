import React, {Component} from 'react';
import { Row, Col } from 'react-grid-system';
import Api from '../Api';
import Localization from '../Localization';
export default class InputPrice extends Component {

	state = {
		price: this.props.value ?? 0,
		priceMinusCommission: 0
	}

	componentDidMount(){
		this.updateCommission(this.props.value ?? 0);
	}

	componentDidUpdate(prev){
		if (prev !== this.props)
			this.updateCommission(this.props.value ?? 0);
	}

	setBuyPrice = (e) => {
		this.setState({
			price: e.target.value
		});
		this.updateCommission(e.target.value);
		if (this.props.onChange !== undefined)
			this.props.onChange(e.target.value);
	}

	updateCommission = (price) => {
		Api.call('/item/commission',{
			price: price,
			currency: this.props.currency
		})
		.then(response => {
			if (response.success === true){
				this.setState({
					priceMinusCommission: response.data
				})
			}
		})
	}

	render(){
		return(
			<div className="inputPrice">
				<Row>
					<Col xs={6}>
						<p>
							{Localization.t('com_inputprice_buyer')}:
						</p>
					</Col>
					<Col xs={6}>
						<p>
							{Localization.t('com_inputprice_seller')}:
						</p>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<input type="number" value={this.props.value ?? this.state.price} onChange={this.setBuyPrice} min={0} disabled={this.props.disabled} />
					</Col>
					<Col xs={6}>
						{
							(this.props.currency === null || this.props.currency === undefined || this.props.currency === -1) &&
							<input type="number" value="-" disabled />
						}
						{
							this.props.currency !== null && this.props.currency !== undefined && this.props.currency !== -1 &&
							<input type="number" value={this.state.priceMinusCommission} disabled />
						}
					</Col>
				</Row>
			</div>
		);
	}
}