import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import './App.css';
import Header from './layout/Header';
import MessageBanner from './layout/MessageBanner';
import { BrowserRouter as Router, Switch, Route/*, Link */} from 'react-router-dom';
import ReactGA from 'react-ga';
import HomePage from './pages/HomePage';


import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import LoginBox from './layout/LoginBox';
import Api from './Api';
import DetailPage from './pages/DetailPage';
import TagPage from './pages/TagPage';
import SellPage from './pages/SellPage';
import NotFoudPage from './pages/NotFound';
import Editor from './pages/editor/Editor';
import EditorAll from './pages/editor/EditorAll';
import CartPage from './pages/CartPage';
import Checkout from './pages/checkout/Checkout';
import SearchPage from './pages/SearchPage';
import SettingsPage from './pages/SettingsPage';
import UserPage from './pages/UserPage';
import OrderPage from './pages/OrderPage';
import DispatchPage from './pages/DispatchPage';
import PagePage from './pages/PagePage';
import NotifyPage from './pages/NotifyPage';
import Footer from './layout/Footer';
import ContactPage from './pages/ContactPage';
//import CookieConsent from "react-cookie-consent";

import CookieConsent from "react-cookie-consent";
import Localization from './Localization';

class App extends Component {

	constructor(...args){
		super(...args);
		
		Api.fullRenderPointer = ()=>{
			this.forceUpdate();
		};

		/* State */
		this.state = {
			user: null,
			showLogin: false,
			showRegister: false
		}
		
		Localization.setLanguage(navigator.language.substr(0,2) ?? 'en');

		const cookies = new Cookies();
		/* User auto login */
		var token = cookies.get('token');
		if (token !== null){
			Api._token = token;
			this.updateUser();
		}
		/* Set API pointers */
		Api.loginPointer = this.showLogin;
		Api.registerPointer = this.showRegister
		Api.logoutPointer = this.handleLogout;
		Api.updatePointer = this.updateUser;

		ReactGA.initialize('UA-23548785-1');
		ReactGA.set({page: window.location.pathname});
		ReactGA.pageview(window.location.pathname);
		
	}


	componentDidUpdate(prevProps) {

	}

	updateUser = () => {
		Api.call('/session/login')
		.then(response=>{
			if (response.success === true)
			{
				Api._token = response.data.token;
				this.setState({
					user: response.data.user
				},()=>{
					Localization.setLanguage(response.data.user.lang ?? navigator.language.substr(0,2) ?? 'en');
				});
			}
		})
		.catch(error=>{
			console.log(error);
		})
	}

	render(){
		return (
				<div className="App">
					<Router>
						{
							this.state.showLogin &&
							<LoginBox close={this.showLogin}>
								<LoginForm onLogin={this.handleLogin} />
							</LoginBox>
						}
						{
							this.state.showRegister &&
							<LoginBox close={this.showRegister}>
								<RegisterForm />
							</LoginBox>
						}
						<Switch>
							<Route exact path="/">
								<MessageBanner />
							</Route>
						</Switch>
						<Header
							user={this.state.user}
						/>
						<Switch>
							<Route exact path="/" component={HomePage} />
							<Route path="/sell/:id" component={SellPage} />
							<Route path="/sell" component={SellPage} />

							<Route path="/item/:id" component={DetailPage} />
							<Route path="/i/:id" component={DetailPage} />

							<Route path="/user/:id" component={UserPage} />
							<Route path="/u/:id" component={UserPage} />
							
							<Route path="/cart">
								<CartPage user={this.state.user} />
							</Route>
							<Route path="/checkout">
								<Checkout user={this.state.user} />
							</Route>

							<Route path="/contact">
								<ContactPage user={this.state.user} />
							</Route>

							<Route path="/page/:page" component={PagePage} />

							<Route path="/settings/:group" component={SettingsPage} />
							<Route path="/settings" component={SettingsPage} />

							<Route path="/notify" component={NotifyPage} />

							<Route path="/editor/:id/:group" component={Editor} />
							<Route path="/editor/:id" component={Editor} />
							<Route path="/editor" component={EditorAll} />

							<Route path="/order/:id" component={OrderPage} />

							<Route path="/dispatch/:id" component={DispatchPage} />

							<Route path="/filter/brand/:brand" component={SearchPage} />
							<Route path="/filter/category/:category" component={SearchPage} />
							<Route path="/filter/material/:material" component={SearchPage} />
							<Route path="/filter/color/:color" component={SearchPage} />
							<Route path="/filter/condition/:condition" component={SearchPage} />
							<Route path="/filter/name/:name" component={SearchPage} />
							<Route path="/search/:name" component={SearchPage} />
							<Route path="/search" component={SearchPage} />


							<Route path="/tag/:tag" component={TagPage} />

							<Route component={NotFoudPage} />
						</Switch>
						<Footer />

						{/*
						<CookieConsent
							location="bottom"
							buttonText="Příjmout"
							cookieName="eu-cookies"
							style={{ background: "#363636" }}
							buttonStyle={{ fontSize: "15px", borderRadius: '5px', color: 'black' }}
							expires={150}
							onAccept={()=>{
								const cookies = new Cookies();
								cookies.set("eu-cookies",1,{path:'/'})
							}}
						>
							Tato stránka využívá ke svému fungování soubory cookies. <Link to="/page/cookies">Více informací</Link>
						</CookieConsent>
						*/}
					</Router>

					<CookieConsent
						location="bottom"
						buttonText={Localization.t('cookies_agree')}
						cookieName="CookieAccepted"
						style={{ background: "#2B2B2B" }}
						buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
						expires={150}
						>
						{Localization.t('cookies_text')} <a href="/page/gdpr">{Localization.t('cookies_more')}</a>
						</CookieConsent>
				</div>
			);
	}
	
	/* Login, register, logout */
	showLogin = () => {
		this.setState({
			showLogin: !this.state.showLogin
		});
	}
	showRegister = () => {
		this.setState({
			showRegister: !this.state.showRegister
		});
	}
	handleLogin = (user) => {
		this.setState({
			showLogin: false,
			user: user
		});
	}

	handleLogout = () => {
		Api.call('/session/logout');
		Api._token = null;
		
		
		const cookie = new Cookies();
		cookie.set('token',Api._token,{path:'/'});
		cookie.remove('token',{ path: '/' });

		this.setState({
			user: null
		});
	}
}

export default App;
