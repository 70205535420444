import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import Api from '../Api';
import Localization from '../Localization';
import './Comment.css';
import CommentSection from './CommentSection';

export default class Comment extends Component {

    state = {
        showReply: false
    }

    deleteComment = () => {
        if (window.confirm(Localization.t('comment_delete_confirm'))){
            Api.call('/comments/delete',{
                id: this.props.data.ID
            })
            .then(response=>{
                if (response.success){
                    this.props.onDelete();
                } else {
                    alert(Localization.t('comment_delete_error'));
                }
            })
        }
    }

    showReply = () => {
        this.setState({
            showReply: true
        })
    }

	render() {			
		return(
            <div style={{
                margin: '0px 0px 20px 0px'
            }} >
                <Row>
                    <Col xs={1}>
                        <Link to={'/user/'+this.props.data.author.ID}>
                            <img src={this.props.data.author.img} alt="" className="full circle" />
                        </Link>
                    </Col>
                    <Col xs={11}>
                        <div className="comment-content">
                            <b>{this.props.data.author.name}</b><br />
                            {
                                this.props.data.content.split('\n').map((item,key)=>(
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                ))
                            }
                            {
                                this.props.data.author.me &&
                                <div style={{
                                    overflow: 'hidden'
                                }}>
                                    <span style={{
                                        cursor: 'pointer', 
                                        color: 'red',
                                        float: 'right',
                                        fontSize: '0.9em'
                                    }}
                                    onClick={this.deleteComment}
                                    >{Localization.t('comment_delete')}</span>
                                </div>
                            }
                        </div>
                        {
                            this.state.showReply && this.props.allowReply &&
                            <div>
                                <br />
                                <CommentSection id={this.props.data.ID} type="cmt" />
                            </div>
                        }
                        {
                            this.props.allowReply && this.state.showReply === false && this.props.data.reply > 0 &&
                            <span style={{
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    display: 'block',
                                    color: '#2196F3',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                                onClick={this.showReply}
                            >{Localization.t('comment_show_reply')} ({this.props.data.reply})</span>
                        }
                        {
                            this.props.allowReply && this.state.showReply === false && this.props.data.reply === 0 &&
                            <span style={{
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    display: 'block',
                                    color: '#c5c5c5',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}
                                onClick={this.showReply}
                            >{Localization.t('comment_reply')}</span>
                        }
                    </Col>
                </Row>
            </div>
		);
	}
}