import React, {Component} from 'react';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import './SellPage.css';
import Api from '../Api';
import InputPrice from '../components/InputPrice';
import Localization from '../Localization';

export default class SellPage extends Component {

	state = {
		step: 1,
		loading: true,
		brands: [],
		colors: [],
		materials: [],
		categories: [],
		error: null,

		gender: -1,
		category: -1,
		name: '',
		brand: -1,
		material: -1,
		size: '',
		color: -1,
		tags: '',
		condition: -1,
		description: '',
		price: 0,
		currency: -1
	}

	loadingState = 0;
	componentDidMount() {
		Api.call("/zlist/brands")
		.then(response=>{
			if (response.success === true){
				this.setState({
					brands: response.data
				})
			}
			this.initStepLoaded();
		})

		Api.call("/zlist/colors")
		.then(response=>{
			if (response.success === true){
				this.setState({
					colors: response.data
				})
			}
			this.initStepLoaded();
		})

		Api.call("/zlist/materials")
		.then(response=>{
			if (response.success === true){
				this.setState({
					materials: response.data
				})
			}
			this.initStepLoaded();
		})

		Api.call("/zlist/categories")
		.then(response=>{
			if (response.success === true){
				this.setState({
					categories: response.data
				})
			}
			this.initStepLoaded();
		});

		if (Api._token === undefined || Api._token === null)
				Api.loginPointer();
	}

	initStepLoaded = () => {
		this.loadingState++;
		if (this.loadingState === 4) {
			this.setState({
				loading: false
			})
		}
	}

	submit = () => {
		if (this.check()) {
			this.setState({
				loading: true
			})



			Api.call("/item/create",this.state)
			.then(response=>{
				if (response.success === true){
					window.location.href = '/editor/' + response.data.id + '/images';
				}
				else
				{
					this.setState({
						loading: false,
						error: Localization.t('sell_error_save')
					})
				}
			})
			.catch(()=>{
				this.setState({
					loading: false,
					error: Localization.t('sell_error_save')
				})
			})
		}
	}

	nextStep = (e) => {
		e.preventDefault();
		if (this.check()){
			this.setState({
				step: this.state.step + 1
			});
		}
	}
	prevStep = (e) => {
		e.preventDefault();
		this.setState({
			step: this.state.step - 1
		});
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleConditionChange = e => {

		var val = 6 - e.target.value;

		this.setState({
			[e.target.name]: val
		});
	}
	
	check = () => {
		var err = null;
		if (this.state.step > 0){
			if (this.state.gender === -1)
				err = 'sell_missing_group';
			if (this.state.category === -1)
				err = "sell_missing_category";
			if (this.state.name === '')
				err = "sell_missing_name";
			if (this.state.brand === -1)
				err = "sell_missing_brand";
		}
		if (this.state.step > 1) {
			if (this.state.material === -1)
				err = "sell_missing_material";
			if (this.state.size === '')
				err = "sell_missing_size";
			if (this.state.color === -1)
				err = "sell_missing_color";
		}
		if (this.state.step > 2) {
			if (this.state.condition === -1)
				err = "sell_missing_state";
			if (this.state.description === '')
				err = "sell_missing_desc";
		}
		if (this.state.step > 3) {
			if (this.state.price < 1)
				err = "sell_error_price_low";
			if (this.state.currency === -1)
				err = "sell_missing_currency";
		}
		this.setState({
			error: err
		});
		return err === null;
	}

	render(){
		if (Api._token === undefined || Api._token === null){
			return(
			<Container>
				<span className="error">{Localization.t('login_required')}</span>
			</Container>
			);
		}
		else
		{
			var optgroups = [];
			for (let index = 0; index < this.state.categories.length; index++) {
				const element = this.state.categories[index];
				if (!optgroups.includes(element.group_name))
					optgroups.push(element.group_name);
			}
			return(
				<div className="sellPage">
					{
						this.state.loading === true &&
						<div style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.7)',
							top: '0px',
							left: '0px',
							zIndex: 5
						}}>
							<p style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%,-100%)',
								fontSize: '2em',
    							fontWeight: 'bold'
							}}>{Localization.t('loading')}</p>
						</div>
					}
					{
						this.state.error !== null &&
						<Container>
							<span className="error">{Localization.t(this.state.error)}</span>
						</Container>
					}
					<div className="step1" style={{
						textAlign: "center",
						display: this.state.step === 1 ? 'block' : 'none'
					}}>
						<Container>
							<h1>{Localization.t('sell_title')}</h1>
							<Row>
								<Col xs={0} md={2} lg={4} />
								<Col xs={12} md={8} lg={4}>
									<Row>
										<Col xs={3} style={{padding:'0px'}}>
											<input id="cat1" type="radio" name="gender" value="1" onChange={this.handleChange} />
											<label htmlFor="cat1" style={{borderRadius: '5px 0px 0px 5px'}}>{Localization.t('sell_target_group_male')}</label>
										</Col>
										<Col xs={3} style={{padding:'0px'}}>
											<input id="cat2" type="radio" name="gender" value="2" onChange={this.handleChange} />
											<label htmlFor="cat2">{Localization.t('sell_target_group_famale')}</label>
										</Col>
										<Col xs={3} style={{padding:'0px'}}>
											<input id="cat3" type="radio" name="gender" value="3" onChange={this.handleChange} />
											<label htmlFor="cat3">{Localization.t('sell_target_group_child')}</label>
										</Col>
										<Col xs={3} style={{padding:'0px'}}>
											<input id="cat4" type="radio" name="gender" value="4" onChange={this.handleChange} />
											<label htmlFor="cat4" style={{borderRadius: '0px 5px 5px 0px'}}>{Localization.t('sell_target_group_others')}</label>
										</Col>
									</Row>

									<h2>{Localization.t('editor_input_category')}</h2>
									<select name="category" onChange={this.handleChange} defaultValue={this.state.category}>
										<option disabled value="-1">{Localization.t('editor_input_category_placeholder')}</option>
										{
											optgroups.map(group=>(
												<optgroup label={group ?? '-'}>
													{
														(this.state.categories ?? []).filter(x=>x.group_name === group).map(category => (
															<option key={category.ID} value={category.ID}>{category.name}</option>
														))
													}
												</optgroup>
											))
										}
									</select>
									
									<h2>{Localization.t('editor_input_name')}</h2>
									<input type="text" name="name" placeholder={Localization.t('editor_input_name_placeholder')} onChange={this.handleChange} />

									<h2>{Localization.t('editor_input_brand')}</h2>
									<select name="brand" onChange={this.handleChange} defaultValue={this.state.brand}>
										<option disabled value="-1">{Localization.t('editor_input_brand_placeholder')}</option>
										{
											this.state.brands.map(brand=>{
												return(
													<option key={brand.ID} value={brand.ID}>{brand.name}</option>
												);
											})
										}
									</select>
								</Col>
							</Row>
							<Row>
								<Hidden xs sm>
									<Col md={2} lg={4} />
								</Hidden>
								<Col xs={12} md={8} lg={4}>
									<input type="submit" value={Localization.t('next')} onClick={this.nextStep} />
								</Col>
							</Row>
							
						</Container>
					</div>
					<div className="step2" style={{
						textAlign: "center",
						display: this.state.step === 2 ? 'block' : 'none'
					}}>
						<Container>
							<h1>{Localization.t('sell_title')}</h1>
							<Row>
								<Col xs={0} md={2} lg={4} />
								<Col xs={12} md={8} lg={4}>
									<h2>{Localization.t('editor_input_material')}</h2>
									<select name="material" onChange={this.handleChange}>
										<option value="-1">{Localization.t('editor_input_material_placeholder')}</option>
										{
											this.state.materials.map(material=>{
												return(
													<option key={material.ID} value={material.ID}>{material.name}</option>
												);
											})
										}
									</select>

									<h2>{Localization.t('editor_input_size')}</h2>
									<input type="text" name="size" placeholder={Localization.t('editor_input_size_placeholder')} value={this.state.size} onChange={this.handleChange} />

									<h2>{Localization.t('editor_input_color')}</h2>
									<select name="color" onChange={this.handleChange}>
										<option value="-1">{Localization.t('editor_input_color_placeholder')}</option>
										{
											this.state.colors.map(color=>{
												return(
													<option key={color.ID} value={color.ID}>{color.name}</option>
												);
											})
										}
									</select>
								</Col>
							</Row>
							<Row>
								<Hidden xs sm>
									<Col md={2} lg={4} />
								</Hidden>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('back')} className="btn-second" onClick={this.prevStep} />
								</Col>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('next')} onClick={this.nextStep} />
								</Col>
							</Row>
							
						</Container>
					</div>
					<div className="step3" style={{
						textAlign: "center",
						display: this.state.step === 3 ? 'block' : 'none'
					}}>
						<Container>
							<h1>{Localization.t('sell_title')}</h1>
							<Row>
								<Col xs={0} md={2} lg={4} />
								<Col xs={12} md={8} lg={4}>
									<h2>{Localization.t('editor_input_tags')}</h2>
									<small>{Localization.t('editor_input_tags_tip')}</small>
									<input type="text" name="tags" placeholder={Localization.t('editor_input_tags_placeholder')} onChange={this.handleChange}/>

									<h2>{Localization.t('item_prop_state')}</h2>
									<select defaultValue={"-1"} name="condition" onChange={this.handleChange}>
										<option disabled value="-1">{Localization.t('item_prop_state_placeholder')}</option>
										<option value={1}>{Localization.t('item_prop_state_1')}</option>
										<option value={2}>{Localization.t('item_prop_state_2')}</option>
										<option value={3}>{Localization.t('item_prop_state_3')}</option>
										<option value={4}>{Localization.t('item_prop_state_4')}</option>
										<option value={5}>{Localization.t('item_prop_state_5')}</option>
									</select>


									<h2>{Localization.t('item_prop_desc')}</h2>
									<textarea name="description" placeholder={Localization.t('item_prop_desc_placeholder')} style={{
										width: '100%',
										resize: 'none',
										height: '300px'
									}} onChange={this.handleChange} value={this.state.description} />
								</Col>
							</Row>
							<Row>
								<Hidden xs sm>
									<Col md={2} lg={4} />
								</Hidden>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('back')} className="btn-second" onClick={this.prevStep} />
								</Col>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('next')} onClick={this.nextStep} />
								</Col>
							</Row>
						</Container>
					</div>
					<div className="step4" style={{
						textAlign: "center",
						display: this.state.step === 4 ? 'block' : 'none'
					}}>
						<Container>
							<h1>{Localization.t("sell_title")}</h1>
							<Row>
								<Col xs={0} md={2} lg={4} />
								<Col xs={12} md={8} lg={4}>

									<h2>{Localization.t('editor_currency')}</h2>
									<select defaultValue="-1" name="currency" onChange={this.handleChange}>
										<option disabled value="-1">{Localization.t('editor_currency_placeholder')}</option>
										<option value="czk">Česká koruna (CZK)</option>
										<option value="eur">Euro (EUR)</option>
									</select>

									<h2>{Localization.t('editor_price')}</h2>
									<InputPrice value={this.state.price} currency={this.state.currency} onChange={(value)=>{
										this.setState({
											price: value
										})
									}} />
								</Col>
							</Row>
							<Row>
								<Hidden xs sm>
									<Col md={2} lg={4} />
								</Hidden>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('back')} className="btn-second" onClick={this.prevStep} />
								</Col>
								<Col xs={6} md={4} lg={2}>
									<input type="submit" value={Localization.t('next')} onClick={this.submit} />
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			);
		}
	}
}