import React from 'react';
import './Chip.css';
import {Row, Col, Hidden} from 'react-grid-system';

export default function AdvantageBox(props) {
	return(
		<Row>
			<Col sm={12} md={4}>
				<img src={props.img} alt="" className="full circle" />
			</Col>
			<Hidden xs sm>
				<Col xs={8}>
					<div className="textVerticalCenter">
						{props.text}
					</div>
				</Col>
			</Hidden>
		</Row>
	);
}