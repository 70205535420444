import React, {Component} from 'react';
import Api from '../Api';
import Notification from '../layout/Notification';
import Localization from '../Localization';

export default class NotifyPage extends Component {

	state = {
		notifications: []
	}

	componentDidMount(){
		this.updateNotifications();
	}

	updateNotifications = () => {
		Api.call('/notifications')
		.then(response=>{
			if (response.success === true){
				this.setState({
					notifications: response.data
				});
			}
		})
		.catch(()=>{})
	}

	notifyRead = (id) => {
		Api.call('/notifications/read',{id: id})
		.then(()=>{
			this.updateNotifications();
		})
	}

	render(){
		return(
			<div>
				{
					this.state.notifications.length === 0 &&
					<Notification icon="/img/notify/welcome.png" title="Vítejte na IloveLV" text="Zde se Vám zobrazí všechna nová upozornění" />
				}
				{
					this.state.notifications.length > 0 && this.state.notifications[0].readed === 0 &&
					<p style={{
						paddingTop: '0px',
						margin: '5px'
					}}>{Localization.t('notify_new')}</p>
				}
				{
					this.state.notifications.map((notify,key) => (
					<div key={key}>
						{
							notify.readed === 1 && key !== 0 && this.state.notifications[key-1].readed === 0 &&
								<p style={{
									borderTop: '1px solid #EAEAEA',
									paddingTop: '5px',
									margin: '5px'
								}}>{Localization.t('notify_old')}</p>
							}
							<Notification icon={notify.img} title={notify.title} text={notify.text} link={notify.link} onClick={()=>{this.notifyRead(notify.ID)}} />
						</div>
					))
				}
			</div>
		);
	}
}