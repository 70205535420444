import React from 'react';
import { Row, Col } from 'react-grid-system';
import './Notification.css';

export default function Notification(props){
	return(
		<div className="notification" onClick={props.onClick}>
			<a href={props.link}>
				<Row>
					<Col xs={2} style={{
						paddingRight: '0px'
					}}>
						<img src={props.icon} alt="" className="full circle" />
					</Col>
					<Col>
						<p className="title">{props.title}</p>
						<p className="text">{props.text}</p>
					</Col>
				</Row>
			</a>
		</div>
	);
}