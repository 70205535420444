import React, { Component } from 'react';

export default class StaticPageParser extends Component{

	render(){
        if (this.props.json === null || this.props.json === undefined)
            return (<div />);

		return(
            <div>
				{
					JSON.parse(this.props.json.content).blocks.map((block,key)=>{
						if (block.type === 'header'){
							switch(block.data.level){
								default:
								case 1:
											return(<h1 key={key}>{block.data.text}</h1>);
										case 2:
											return(<h2 key={key}>{block.data.text}</h2>);
										case 3:
											return(<h3 key={key}>{block.data.text}</h3>);
										case 4:
											return(<h4 key={key}>{block.data.text}</h4>);
										case 5:
											return(<h5 key={key}>{block.data.text}</h5>);
										case 6:
											return(<h6 key={key}>{block.data.text}</h6>);
									}
								}
								else if (block.type === 'paragraph'){
									return (<p key={key} dangerouslySetInnerHTML={{__html: block.data.text}}></p>);
								}
								else if (block.type === 'list'){
									if (block.data.style === 'ordered'){
										return (<ol key={key}>
											{
												block.data.items.map((item,subkey)=>{
													return (<li key={subkey}>{item}</li>)
												})
											}
										</ol>)
									} else {
										return (<ul key={key}>
											{
												block.data.items.map((item,subkey)=>{
													return (<li key={subkey}>{item}</li>)
												})
											}
										</ul>)
									}
								}
								else if (block.type === 'image'){
									return (
										<img key={key} src={block.data.url} alt={block.data.caption} style={{
											width: block.data.stretched === true ? '100%' : 'initial',
											display: 'block',
											margin:  block.data.withBackground === true ? 'auto' : '0px',
											maxWidth: '100%'
										}} />
									);
								}
								else if (block.type === 'checklist') {
									return block.data.items.map((item,subkey)=>(
										<div key={key+"-"+subkey}>
											<input type="checkbox" checked={item.checked} style={{
												width: 'initial',
												marginRight: '5px'
											}} />
											<label>{item.text}</label>
										</div>
									));
								}
				    	else
					return JSON.stringify(block);
				})
			}
            </div>
		);
	}
}