import React, {Component} from 'react';

export default class ImgMagify extends Component {

	state = {
		offsetX: 0,
		offsetY: 0,
		hover: false,
		zoom: 2
	}

	componentDidMount(){
		this.refs.node.addEventListener('wheel',(e)=>{
			e.preventDefault();
		});
	}
	

	move = (e) => {
		e = e.nativeEvent;
		var zoomer = this.refs.node;

		var offsetX = 0;
		var offsetY = 0;
		if (e.offsetX){
			offsetX  = e.offsetX
		} else {
			if (e.touches && e.touches.length > 0)
				offsetX = e.touches[0].pageX;
			else
				offsetX = 0;
		}

		if (e.offsetY){
			offsetY = e.offsetY;
		} else {
			if (e.touches && e.touches.length > 0)
				offsetY = e.touches[0].pageY;
			else
				offsetY = 0;
		}


		var x = offsetX/zoomer.offsetWidth*100;
		var y = offsetY/zoomer.offsetHeight*100;

		this.setState({
			offsetX: x,
			offsetY: y,
			hover: true
		});
	}


	render(){
		return(
			<div ref="node" className="magnifynode">
				<img src={this.props.src} alt={this.props.alt ?? ''} ref="img" />
				<div
					className="maginfy"
					style={{
						transition: 'opacity 0.2s, background-size 0.2s',
						position: 'absolute',
						top: '0px',
						left: '0px',
						bottom: '0px',
						right: '0px',
						opacity: this.state.hover ? 1 : 0,
						backgroundRepeat: 'no-repeat',
						backgroundColor: '#eee',
						backgroundImage: 'url("'+this.props.src+'")',
						backgroundSize: (this.state.zoom*100)+'%',
						backgroundPosition: this.state.offsetX + '% ' + this.state.offsetY + '%',
						cursor: 'all-scroll'
					}}
					onMouseMove={this.move}
					onMouseLeave={()=>{
						this.setState({
							hover: false
						});
					}}
					onWheel={(e)=>{
						var zoom = this.state.zoom;
						zoom += e.nativeEvent.wheelDelta > 0 ? 0.5 : -0.5;
						if (zoom > 1 && zoom < 6){
							this.setState({
								zoom: zoom
							});
						}
					}}
				/>
			</div>
		);
	};
}