import React, {Component} from 'react';
import Chip from './../layout/Chip'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import Api from './../Api';
import SearchResults from '../components/SearchResults';
import Like from '../components/Like';
import CommentSection from '../components/CommentSection';
import Localization from '../Localization';

export default class UserPage extends Component {

	state = {
		id: this.props.match.params.id,
		user: {}
	}

	componentDidMount(){
		this.updateUser();
	}

	updateUser = () => {
		Api.call('/user',{
			id: this.state.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					user: response.data
				})
			}
		})
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		return(
			<div>
				<Container>
					<Row>
						<Visible xs sm>
							<Col xs={3} sm={4} />
						</Visible>
						<Col xs={6} sm={4} md={2}>
							<img src={this.state.user.img} alt={this.state.user.name} className="full circle" />
						</Col>
						<Visible xs sm>
							<Col xs={3} />
						</Visible>
						<Col sm={12} md={9}>
							<Visible xs sm>
								<h1 style={{
									textAlign:'center'
								}}>{this.state.user.name}</h1>
							</Visible>
							<Hidden xs sm>
								<h1>{this.state.user.name}</h1>
							</Hidden>
							<Visible xs sm>
								<Row>
									<Col xs={5} />
									<Col xs={2}>
										<Like type="usr" id={this.state.id} />
									</Col>
								</Row>
							</Visible>
							{
								this.state.user.legal_entity === 1 &&
								<Chip big="true" img="/img/icon/guarantee.png" type="expert">
									Expert seller
								</Chip>
							}
							{
								(this.state.user.verified === 1 && this.state.user.legal_entity === 0) &&
								<Chip big="true" img="/img/icon/guarantee.png" type="trusted">
									Trusted seller
								</Chip>
							}
						</Col>
						<Hidden xs sm>
							<Col xs={1}>
								<Like type="usr" id={this.state.id} />
							</Col>
						</Hidden>
					</Row>
					<br />

					<Hidden xs sm>
						<Row style={{
							textAlign: 'center',
							fontSize: '1.3em'
						}}>
							<Col>
								<b>{Localization.t('settings_general_country')}</b><br />
								<span>{this.state.user.country}</span>
							</Col>
							{
								this.state.user.legal_entity === 1 &&
								<Col>
									<b>{Localization.t('settings_seller_ic')}</b><br />
									<span>{this.state.user.IC}</span>
								</Col>
							}
							{
								this.state.user.legal_entity === 1 &&
								<Col>
									<b>{Localization.t('settings_seller_dic')}</b><br />
									<span>{this.state.user.DIC}</span>
								</Col>
							}
							<Col>
								<b>{Localization.t('profile_item_count')}</b><br />
								<span>{this.state.user.item_count}</span>
							</Col>
						</Row>
					</Hidden>
					<Visible xs sm>
						<div>
							<b>{Localization.t('settings_general_country')}: </b>
							<span>{this.state.user.country}</span><br />
						</div>
						{
							this.state.user.legal_entity === 1 &&
								<div>
									<b>{Localization.t('settings_seller_ic')}: </b>
									<span>{this.state.user.IC}</span>
									<br />
								</div>
							}
							{
								this.state.user.legal_entity === 1 &&
								<div>
									<b>{Localization.t('settings_seller_dic')}: </b>
									<span>{this.state.user.DIC}</span>
									<br />
								</div>
							}
							<div>
								<b>{Localization.t('profile_item_count')}: </b>
								<span>{this.state.user.item_count}</span>
								<br />
							</div>
					</Visible>
				</Container>
				<div style={{
					background: '#eeeeee',
					paddingTop: '10px',
					marginTop: '10px'
				}}>
					<Container>
						<h1 id="comments">{Localization.t('profile_comments')}</h1>
						<CommentSection id={this.state.id} type="usr" allowReply={true} />
						<h1 id="items">{Localization.t('profile_items')}</h1>
						<SearchResults user={this.state.id} orderBy="ID" desc={true} />
					</Container>
				</div>
			</div>
		);
	}
}