import React, { Component } from 'react';
import Api from '../Api';
import Localization from '../Localization';
import Comment from './Comment';
import CommentWriter from './CommentWriter';

export default class CommentSection extends Component {

	state = {
		comments: []
	}

	componentDidMount(){
		this.updateComments();
	}

	componentDidUpdate(prevProps, prevState){
		if (prevProps.type !== this.props.type || prevProps.id !== this.props.id){
			this.updateComments();
		}
	}

	updateComments = () => {
		Api.call('/comments',{
			type: this.props.type,
			section: this.props.id
		}).then(response=>{
			if (response.success){
				this.setState({
					comments: response.data
				})
			}
		})
	}

	render() {
		if (this.props.id === null || this.props.id === undefined || this.props.type === undefined || this.props.type === null)
			return(
				<div>
					{Localization.t('comment_loading_error')}
				</div>
			);

		return(
			<div>
				{
					this.state.comments.map((item,key)=>(
						<Comment key={key} data={item} onDelete={this.updateComments} allowReply={this.props.allowReply} />
					))
				}
				<CommentWriter section={this.props.id} type={this.props.type} onSend={this.updateComments} />
			</div>
		);
	}
}