import React, {Component} from 'react';
import ItemWidget from '../layout/ItemWidget';
import Api from '../Api';
import { Row, Col } from 'react-grid-system';
import Localization from '../Localization';

export default class SearchResults extends Component{

	state = {
		page: 0,
		items: [],
		canLoadMore: true
	}

	lastLoadedPage = -1;

	infiniteScroll = null;

	componentDidMount(){
		this.search(false);
		this.infiniteScroll = setInterval(()=>{
			if(this.state.canLoadMore && document.body.scrollHeight - window.innerHeight - window.scrollY - 500 < 0){
				this.nextPage();
				console.log('SPAM');
			}
		},500);
	}


	componentWillUnmount(){
		clearInterval(this.infiniteScroll);
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props){
			this.search(true);
		}
	}

	nextPage = () => {
		this.setState({
			page: this.state.page + 1
		},()=>{
			this.search(false);
		})
	}

	search = (research = false) => {

		if (this.lastLoadedPage === this.state.page &&!research)
			return;
		else
			this.lastLoadedPage = this.state.page;

		if (research)
			this.lastLoadedPage = -1;

		var searchParams = {};
		if (this.props.name !== undefined)
			searchParams.name = this.props.name;
		if (this.props.brand !== undefined)
			searchParams.brand = this.props.brand;
		if (this.props.model !== undefined)
			searchParams.model = this.props.model;
		if (this.props.material !== undefined)
			searchParams.material = this.props.material;
		if (this.props.color !== undefined)
			searchParams.color = this.props.color;
		if (this.props.description !== undefined)
			searchParams.description = this.props.description;
		if (this.props.size !== undefined)
			searchParams.size = this.props.size;
		if (this.props.priceMin !== undefined)
			searchParams.priceMin = this.props.priceMin;
		if (this.props.priceMax !== undefined)
			searchParams.priceMax = this.props.priceMax;
		if (this.props.currency !== undefined)
			searchParams.currency = this.props.currency;
		if (this.props.condition !== undefined)
			searchParams.condition = this.props.condition;
		if (this.props.tags !== undefined)
			searchParams.tags = this.props.tags;
		if (this.props.gender !== undefined)
			searchParams.gender = this.props.gender;
		if (this.props.category !== undefined)
			searchParams.category = this.props.category;
		if (this.props.user !== undefined)
			searchParams.user = this.props.user
		if (this.props.directShipping !== undefined)
			searchParams.directShipping = this.props.directShipping

		if (this.props.orderBy !== undefined)
			searchParams.orderBy = this.props.orderBy;
		if (this.props.count !== undefined)
			searchParams.count = this.props.count;
		if (this.props.desc !== undefined && this.props.desc === true)
			searchParams.desc = true;
		searchParams.page = this.state.page;
		

		Api.call("/item/search",searchParams)
		.then(response=>{
			this.setState({
				items: response.success === true && (response.data ?? []).length > 0 ? (research ? response.data : this.state.items.concat(response.data)) : [],
				canLoadMore: (response.data ?? []).length >= 30
			});
		})
		.catch(error=>{
			console.error(error);
		});
	}

	render(){
		return(
			<div>
				<Row>
					{this.state.items.map(item=>{
						return (
							<Col xs={6} md={this.props.small === true ? 12 : 6} lg={this.props.small === true ? 4 : 3} key={item.id} style={{
								padding: '5px'
							}}>
								<ItemWidget item={item} />
							</Col>
						);
					})}
				</Row>
				{
					this.state.canLoadMore &&
					<Row>
						<Col xs={12} md={2} lg={3} />
						<Col xs={12} md={4} lg={6}>
							<button className="btn" onClick={this.nextPage}>{Localization.t('com_lazyload_more')}</button>
						</Col>
					</Row>
				}
				
			</div>
		);
	}
}