import React, { Component } from 'react';
import './MessageBanner.css';
import Api from '../Api';

export default class MessageBanner extends Component{

	componentDidMount(){

		this.updateText();

		setInterval(()=>{
			var newactiveMessage = this.state.activeMessage < this.state.messages.length - 1 ? this.state.activeMessage + 1 : 0
			this.setState({
				activeMessage: newactiveMessage
			});
		},this.state.interval);
	}

	state = {
		activeMessage: 0,
		interval: 5000,
		messages: []
	}

	updateText = () => {
		Api.call('/campaign')
		.then(data=>{
			this.setState({
				messages: data.data
			})
		})
		.catch(err=>{
			console.warn(err);
		});
	}

	render(){
		if (this.state.messages !== undefined && this.state.messages !== null)
			return(
				<div className="messageBanner" style={{
					backgroundColor: this.state.messages[this.state.activeMessage] !== undefined ? this.state.messages[this.state.activeMessage].background ?? 'rgb(43, 43, 43)' : 'rgb(43, 43, 43)'
				}}>
					<div className="messageContent">
						{
							this.state.messages !== undefined &&
							this.state.messages.map((item, key)=>{
								return(
									<a href={item.href ?? null} key={key}>
										<p className={key === this.state.activeMessage ? 'on' : 'off'} style={{
											color: item.color ?? 'white'
										}}>
											{item.text}
										</p>
									</a>
								);
							})
						}
					</div>
				</div>
			);
		else
			return(<div />);
	}
}