import React, { Component } from 'react';
import './Header.css';
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system';
import Search from './Search';
import { Link } from 'react-router-dom';
import HeaderQuickBar from './HeaderQuickBar';
import MobileMenu from './MobileMenu';
import Api from '../Api';
import Localization from '../Localization';

export default class Header extends Component{

	state = {
		magicLineLeft: 0,
		magicLineWidth: 0,
		mobileMenuOpen: false,
		submenu: '',
		menu: []
	}

	componentDidMount(){
		this.updateMenu();
	}



	updateMenu = () => {

		Api.call("/zlist/brands")
		.then(response=>{
			if (response.success === true){

				var four = response.data;

				var half_length = Math.ceil(four.length / 2);    
				var two = four.splice(0,half_length);

				half_length = Math.ceil(two.length / 2);    
				var one = two.splice(0,half_length);

				half_length = Math.ceil(four.length / 2);    
				var three = four.splice(0,half_length);


				this.setState({
					menu: [
						{
							text: Localization.t('menu_nav_new'),
							href: "/search"
						},
						{
							text: Localization.t('menu_nav_brands'),
							sub: [
								{
									items: one.map(brand=>{
										return {
											text: brand.name,
											href: "/filter/brand/"+brand.ID
										}
									})
								},
								{
									items: two.map(brand=>{
										return {
											text: brand.name,
											href: "/filter/brand/"+brand.ID
										}
									})
								},
								{
									items: three.map(brand=>{
										return {
											text: brand.name,
											href: "/filter/brand/"+brand.ID
										}
									})
								},
								{
									items: four.map(brand=>{
										return {
											text: brand.name,
											href: "/filter/brand/"+brand.ID
										}
									})
								}
							]
						},
						{
							text: Localization.t('menu_nav_purses'),
							href: "/filter/category/Kabelky"
						},
						{
							text: Localization.t('menu_nav_clothes'),
							href: "/filter/category/Oble%C4%8Den%C3%AD"
						},
						{
							text: Localization.t('menu_nav_shoes'),
							href: "/filter/category/Boty"
						},
						{
							text: Localization.t('menu_nav_accessories'),
							href: "/filter/category/Dopl%C5%88ky"
						},
						{
							text: Localization.t('menu_nav_originality'),
							href: "/page/originality"
						}
					]
				});

			}
		})
	}

	handleMobileMenu = () => {
		var isOpen = this.state.mobileMenuOpen;
		this.setState({
			mobileMenuOpen: !isOpen
		})
	}

	render(){
		return(
			<header className="header">
				<div className="mainHeader mainLine">
					<Container>
						<Row>
							<Visible xs sm>
								<Col xs={1} style={{
									padding: '0px'
								}}>
									<Link to="/">
										<img src="/favicon.png" className="logo" alt="ILOVELV" style={{
											width: '100%',
											height: 'auto'
										}} />
									</Link>
								</Col>
							</Visible>
							<Col xs={10} md={4}>
								<Search />
							</Col>
							<Hidden xs sm>
								<Col sm={4}>
									<Link to="/">
										<img src="/logo2.png" className="logo" alt="ILOVELV" />
									</Link>
								</Col>
								<Col sm={4}>
									<HeaderQuickBar user={this.props.user} />
								</Col>
							</Hidden>
							<Visible xs sm>
								<Col xs={1} style={{
									padding: '0px'
								}}>
									<img src="/img/icon/menu.png" onClick={this.handleMobileMenu} alt="" style={{
										width: '100%',
										height: 'auto',
										margin: '10px 0px',
										boxSizing: 'border-box',
										transform: 'translate(-25%)'
									}} />
								</Col>
							</Visible>
						</Row>
					</Container>
				</div>
				{
					this.state.mobileMenuOpen &&
					<MobileMenu menu={this.state.menu} close={this.handleMobileMenu} user={this.props.user} />
				}
				<Hidden xs sm>
					<div onMouseLeave={this.unsetMagicLine}>
						<div className="mainHeader secondLine">
							<Container>
								<Row>
									<Col xs={12}>
										<div className="menuItems">
											{
												(this.state.menu ?? []).map((menuItem,key)=>{
													return(
														<div key={key} onMouseEnter={this.setMagicLine} className="menuItem active" style={{
															cursor: 'pointer'
														}}>
															{
																'href' in menuItem &&
																<Link to={menuItem.href}>
																	{menuItem.text}
																</Link>

															}
															{
																!('href' in menuItem) &&
																	menuItem.text
															}
															{
																'sub' in menuItem &&
																<div className="submenu" onMouseEnter={this.stopPropagation}>
																	<Container>
																		<Row>
																			{
																				menuItem.sub.map((category,key)=>{
																					return(
																						<Col xs={3} key={key} >
																						<span className="title">{category.text ?? ''}</span>
																						{
																							(category.items ?? []).map((item,key) => {
																								return(
																									<a key={key} href={item.href ?? ''}>{item.text ?? ''}</a>
																								);
																							})
																						}
																					</Col>
																					);
																				})
																			}
																		</Row>
																	</Container>
																</div>
															}
														</div>
													);
												})
											}
											<div style={{
												width: this.state.magicLineWidth+'px',
												height: '2px',
												backgroundColor: '#a16d5d',
												position: 'absolute',
												left: this.state.magicLineLeft+'px',
												transition: 'all 0.3s',
												opacity: this.state.magicLineOpacity,
												transform: 'translateY(-2px)'
											}} />
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="mainHeader secondLine" id="submenu" dangerouslySetInnerHTML={{__html:this.state.submenu}} style={{
							display: this.state.submenu === '' ? 'none' : 'block'
						}}>
							
						</div>
					</div>
				</Hidden>
			</header>
		);
	}

	setMagicLine = (e) => {
		if (!e.target.classList.contains('menuItem'))
			e.target = e.target.parentNode;

		var submenu = e.target.querySelector('.submenu');
		if (submenu === null || submenu === undefined)
			submenu = '';

		console.log(submenu);
		
		this.setState({
			magicLineLeft: e.target.offsetLeft,
			magicLineWidth: e.target.offsetWidth,
			magicLineOpacity: 1,
			submenu: submenu === '' ? '' : submenu.outerHTML
		});
	}

	unsetMagicLine = (e) => {
		this.setState({
			magicLineOpacity: 0,
			submenu: ''
		})
	}

	stopPropagation = (e) => {
		e.stopPropagation();
	}
	
}