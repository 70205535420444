
//import Api from './Api';
import CzechStrings from './lang/cz.json';
import EnglishStrings from './lang/en.json';

export default class Localization
{

	static setLanguage = lang => {
		this.lang = lang;
	}

	static lang = 'cz';

	static t = strKey => {
		
		if (strKey in this._getStrings())
			return this._getStrings()[strKey];

		return strKey;
	}

	static _getStrings()
	{
		switch(this.lang)
		{
			default:
			case 'cs':
			case 'cz':
				return CzechStrings;
			case 'en':
				return EnglishStrings;
		}
	}
}