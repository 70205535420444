import React, {Component} from 'react';
import Api from '../../Api';
import Localization from '../../Localization';

export default class EditorAppearance extends Component {

	state = {
		materials: [],
		colors: []
	}

	componentDidMount(){
		Api.call("/zlist/materials")
		.then(response=>{
			if (response.success === true){
				this.setState({
					materials: response.data
				})
			}
		})

		Api.call("/zlist/colors")
		.then(response=>{
			if (response.success === true){
				this.setState({
					colors: response.data
				})
			}
		})
	}

	render(){
		return(
			<div>
				<label style={{
					paddingLeft: '10px'
				}}>{Localization.t('editor_input_material')}</label>
				<select name="material" onChange={this.props.handler} disabled={!(this.props.item ?? []).draft}>
					<option disabled value="-1">{Localization.t('editor_input_material_placeholder')}</option>
					{
						this.state.materials.map(material=>{
							return(
								<option key={material.ID} value={material.ID} selected={material.ID === (this.props.item ?? []).material}>{material.name}</option>
							);
						})
					}
				</select>

				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_input_color')}</label>
				<select name="color" onChange={this.props.handler} disabled={!(this.props.item ?? []).draft}>
					<option disabled value="-1">{Localization.t('editor_input_color_placeholder')}</option>
					{
						this.state.colors.map(color=>{
							return(
								<option key={color.ID} value={color.ID} selected={color.ID === (this.props.item ?? []).color}>{color.name}</option>
							);
						})
					}
				</select>
				
				{/*<Row className="colors">
				{
					this.state.colors.map(color=>{
						return(
							<Col key={color.ID} xs={3} style={{padding:'0px'}}>
								<input id={'color'+color.ID} type="radio" name="color" value={color.ID} onChange={this.props.handler} checked={(this.props.item ?? []).color === color.ID} />
								<label htmlFor={'color'+color.ID} style={{
									background: '#'+color.code
								}}>{color.name}</label>
							</Col>
						);
					})
				}
			</Row>*/}
			</div>
		);
	}
}