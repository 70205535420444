import React, {Component} from 'react';
import './Checkbox.css';

export default class Checkbox extends Component {

	render(){
		return(
			<label className="checkbox">
				{this.props.text}
				<input id={this.props.id} type="checkbox" name={this.props.name} checked={this.props.value} onChange={this.props.onChange} />
				<span className="checkmark"></span>
			</label>
		)
	}
}