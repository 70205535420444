import React, {Component} from 'react';
import './ConnectionCard.css';
import { Row, Col } from 'react-grid-system';
import Localization from '../Localization';

export default class ConnectionCard extends Component {
	render(){
		return(
			<div className="connectioncard">
				<Row>
					<Col xs={3}>
						<img src={this.props.icon} alt="" style={{width: '100%'}} />
					</Col>
					<Col xs={9}>
						<span style={{
							fontSize: '1.5rem',
							fontWeight: 'bold',
							display: 'block'
						}}>{this.props.name}</span>
						{
							this.props.id !== null && this.props.id !== undefined &&
							<button className="btn btn-second hoverZoomOut" onClick={this.props.disconnect}>{Localization.t('settings_connection_remove')}</button>
						}
						{
							(this.props.id === null || this.props.id === undefined) &&
							<button className="btn hoverZoomIn" onClick={this.props.connect}>{Localization.t('settings_connection_connect')}</button>
						}
					</Col>
				</Row>
			</div>
		);
	}
}