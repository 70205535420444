import React, {Component} from 'react';
import { Container } from 'react-grid-system';
import Localization from '../Localization';

export default class NotFoudPage extends Component {
	render(){
		return(
			<Container>
				<h1 style={{
					textAlign: "center"
				}}>{Localization.t('error_404')}</h1>
				<p style={{
					textAlign: "center"
				}}>
					{Localization.t('error_404_desc')}
				</p>
			</Container>
		);
	}
}