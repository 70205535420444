import React, { Component } from 'react';
import { Container, Col, Row, Hidden } from 'react-grid-system';
import Subtotal from '../../components/Subtotal';
import CheckoutTransport from './CheckoutTransport';
import Checkbox from './../../layout/Checkbox';
import Api from '../../Api';
import Localization from '../../Localization';

export default class Checkout extends Component {

	state = {
		loading: false,
		transportList: [],
		transport: null,
		transportData: {
			name: "",
			phone: "+420 ",
			street: "",
			city: "",
			zip: ""
		},
		copyData: true,
		invoiceData: {
			name: "",
			phone: "",
			street: "",
			city: "",
			zip: ""
		}/*,
		coupon: ''-*/
	}

	componentDidMount(){
		this.updateTransportList();
	}


	updateTransportList = () => {
		Api.call('/zlist/transport')
		.then(response=>{
			if (response.success === true)
			{
				this.setState({
					transportList: response.data
				})
			}
		})
	}

	handleTransportChange = (e) => {
		this.setState({
			transport: e.target.value
		})
	}

	handleChange = (e) => {
		var data = this.state.transportData;
		data[e.target.name] = e.target.value;
		this.setState({
			transportData: data
		},()=>{
			console.log(this.state);
		})
	}

	handleInvoiceChange = (e) => {
		var data = this.state.invoiceData;
		data[e.target.name] = e.target.value;
		this.setState({
			invoiceData: data
		})
	}

	handleSwitch = (e) => {
		this.setState({
			[e.target.name]: !e.target.checked
		})
	}

	handleRootChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	submit = () => {

		if (this.state.transport === null){
			alert(Localization.t('checkout_error_missing_transport'));
			return;
		}
		if (this.state.transportData.name === "" || this.state.transportData.phone.length < 6 || this.state.transportData.street === "" || this.state.transportData.street === "" || this.state.transportData.city === "" || this.state.transportData.zip === ""){
			alert(Localization.t('checkout_error_missing_input'));
			return;
		}
		if (this.state.copyData === false && (this.state.invoiceData.name === "" || this.state.invoiceData.phone === "" || this.state.invoiceData.street === "" || this.state.invoiceData.street === "" || this.state.invoiceData.city === "" || this.state.invoiceData.zip === "")) {
			alert(Localization.t('checkout_error_missing_input'));
			return;
		}

		this.setState({
			loading: true
		})

		if (this.state.copyData === true)
		{
			this.setState({
				invoiceData: this.state.transportData
			},()=>{
				Api.call('/order/create',this.state).then(this._submited).catch(()=>{alert(Localization.t('error_500'))});
			})
		}
		else
		{
			Api.call('/order/create',this.state).then(this._submited).catch(()=>{alert(Localization.t('error_500'))});
		}
	}

	_submited = (response) => {
		if (response.success === true) {
			Api.call('/paygopay/pay',{
				id: response.data
			}).then(r=>{
				if (r.success === true)
					window.location.href = response.data;
			})
			window.location.href = Api.url+'/pay/create?id='+response.data;
		} else {
			alert(Localization.t('error_500'));
		}
	}

	find = (array,rule) => {
		for (var i=0; i < array.length; i++) {
			if (typeof array[i] != "object") continue;
			if (rule(array[i])) return array[i];
		}
	}

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		
		var transport = (this.find(this.state.transportList,item => item.ID === this.state.transport) ?? {priceCZ: 0, priceSK: 0});
		return (
			<div className="page checkout">
				{
					this.state.loading === true &&
						<div style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255,255,255,0.7)',
							top: '0px',
							left: '0px',
							zIndex: 5
						}}>
							<p style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%,-100%)',
								fontSize: '2em',
    							fontWeight: 'bold'
							}}>{Localization.t('saving')}</p>
						</div>
					}
				<Container>
					<Row>
						<Col xs={12} md={8}>
							<h2>{Localization.t('checkout_input_transport')}:</h2>
							<Row>
								<Hidden xs sm>
									<Col xs={3} />
								</Hidden>
								{
									this.state.transportList.map(transport=>(
										<Col xs={6} md={3} key={transport.ID}>
											<CheckoutTransport active={this.state.transport === transport.ID} value={transport.ID} name="transport" img={transport.img} onChange={this.handleTransportChange} />
										</Col>
									))
								}
							</Row>

							{/* Doručovací adresa */}
							<h2>{Localization.t('checkout_input_addr')}:</h2>
							<form>
								<Row style={{
									padding: '10px'
								}}>
									<Col xs={12} md={3}>
										{Localization.t('checkout_input_name')}:
									</Col>
									<Col xs={12} md={9}>
										<input type="text" name="name" onChange={this.handleChange} value={this.state.transportData.name} style={{
											padding: '0px',
											margin: '0px'
										}} />
									</Col>
								</Row>

								<Row style={{
									padding: '10px'
								}}>
									<Col xs={12} md={3}>
										{Localization.t('checkout_input_phone')}:
									</Col>
									<Col xs={12} md={9}>
										<input type="text" name="phone" onChange={this.handleChange} value={this.state.transportData.phone} style={{
											padding: '0px',
											margin: '0px'
										}} />
									</Col>
								</Row>

								<Row style={{
									padding: '10px'
								}}>
									<Col xs={12} md={3}>
										{Localization.t('checkout_input_street')}:
									</Col>
									<Col xs={12} md={9}>
										<input type="text" name="street" onChange={this.handleChange} value={this.state.transportData.street} style={{
											padding: '0px',
											margin: '0px'
										}} />
									</Col>
								</Row>

								<Row style={{
									padding: '10px'
								}}>
									<Col xs={12} md={3}>
										{Localization.t('checkout_input_city')}:
									</Col>
									<Col xs={12} md={9}>
										<input type="text" name="city" onChange={this.handleChange} value={this.state.transportData.city} style={{
											padding: '0px',
											margin: '0px'
										}} />
									</Col>
								</Row>

								<Row style={{
									padding: '10px'
								}}>
									<Col xs={12} md={3}>
										{Localization.t('checkout_input_zip')}:
									</Col>
									<Col xs={12} md={9}>
										<input type="text" name="zip" onChange={this.handleChange} value={this.state.transportData.zip} style={{
											padding: '0px',
											margin: '0px'
										}} />
									</Col>
								</Row>
							</form>

							<div>
								<Checkbox id="x" name="copyData" value={!this.state.copyData} onChange={this.handleSwitch} />
							</div>
							<label for="x" style={{
								marginLeft: '30px'
							}}>{Localization.t('checkout_input_invaddr_is_different')}</label>

							
							<div style={{
								display: this.state.copyData ? 'none' : 'block'
							}}>
								<h2>{Localization.t('checkout_input_invaddr')}</h2>
								{/* Fakturační adresa */}
								<form>
									<Row style={{
										padding: '10px'
									}}>
										<Col xs={3}>
											{Localization.t('checkout_input_name')}:
										</Col>
										<Col xs={9}>
											<input type="text" name="name" onChange={this.handleInvoiceChange} value={this.state.invoiceData.name} style={{
												padding: '0px',
												margin: '0px'
											}} />
										</Col>
									</Row>

									<Row style={{
										padding: '10px'
									}}>
										<Col xs={3}>
											{Localization.t('checkout_input_phone')}:
										</Col>
										<Col xs={9}>
											<input type="text" name="phone" onChange={this.handleInvoiceChange} value={this.state.invoiceData.phone} style={{
												padding: '0px',
												margin: '0px'
											}} />
										</Col>
									</Row>

									<Row style={{
										padding: '10px'
									}}>
										<Col xs={3}>
											{Localization.t('checkout_input_street')}:
										</Col>
										<Col xs={9}>
											<input type="text" name="street" onChange={this.handleInvoiceChange} value={this.state.invoiceData.street} style={{
												padding: '0px',
												margin: '0px'
											}} />
										</Col>
									</Row>

									<Row style={{
										padding: '10px'
									}}>
										<Col xs={3}>
											{Localization.t('checkout_input_city')}:
										</Col>
										<Col xs={9}>
											<input type="text" name="city" onChange={this.handleInvoiceChange} value={this.state.invoiceData.city} style={{
												padding: '0px',
												margin: '0px'
											}} />
										</Col>
									</Row>

									<Row style={{
										padding: '10px'
									}}>
										<Col xs={3}>
											{Localization.t('checkout_input_zip')}:
										</Col>
										<Col xs={9}>
											<input type="text" name="zip" onChange={this.handleInvoiceChange} value={this.state.invoiceData.zip} style={{
												padding: '0px',
												margin: '0px'
											}} />
										</Col>
									</Row>
								</form>
								<Row>
									<Col xs={9}>

									</Col>
									<Col xs={3}>
										
									</Col>
								</Row>
							</div>

							{/*<h2>Slevový kód</h2>
							<input type="text" name="coupon" onChange={this.handleRootChange} value={this.state.coupon} />*/}
							
							<Row>
								<Hidden xs sm>
									<Col xs={8}>
									</Col>
								</Hidden>
								<Col xs={12} md={4}>
									<button className="btn" onClick={this.submit}>{Localization.t('checkout_pay')}</button>
								</Col>
							</Row>
						</Col>
						<Col xs={12} md={4}>
							<Subtotal user={this.props.user} additional={
								[
									{
										text: Localization.t('checkout_shipping') + ' (' + (this.props.user ?? {}).country +')',
										price: (this.props.user ?? {}).country === 'cz' ? transport.priceCZ : transport.priceSK
									}
								]
							} cprice={true} />
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}