import React, {Component} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Api from '../Api';
import './editor/Editor.css';
import ConnectionCard from '../layout/ConnectionCard';
import Localization from '../Localization';

export default class SettingsPage extends Component {

	state = {
		group: this.props.match.params.group ?? 'general',
		changed: false,

		fname: '',
		lname:'',
		name: '',
		country: '',
		lang: '',
		pass: true,
		currency: '',
		legal_entity: false,
		bank_account: '',
		bank_number: '',
		IC: '',
		DIC: '',
		img: '/img/newUser.png',

		fb_id: '',

		oldPass: '',
		newPass: '',
		newPassAgin: ''
	}

	tabs = [
		{
			id: 'general',
			name: Localization.t('settings_tab_general')
		},
		{
			id: 'seller',
			name: Localization.t('settings_tab_seller')
		},
		{
			id: 'password',
			name: Localization.t('settings_tab_password')
		},
		{
			id: 'connect',
			name: Localization.t('settings_tab_connection')
		}
	]

	setPassword = () => {
		if (this.state.newPass !== this.state.newPassAgin){
			alert(Localization.t('register_error_passmatch'));
		} else if (this.state.newPass.length < 6) {
			alert(Localization.t('register_error_shortpass'));
		} else {
			Api.call('/settings/setpass',this.state)
			.then(response=>{
				if (response.success === true){
					alert(Localization.t('settings_pass_set'));
					window.location.href = '/';
				} else {
					alert(Localization.t('settings_pass_wrong'));
				}
			})
			.catch(ex=>{
				alert(Localization.t('error_500'));
			});
			this.setState({
				oldPass: '',
				newPass: '',
				newPassAgin: ''
			})
		}
	}

	hangeGroup = (e) => {
		this.setState({
			group: e.target.id
		})
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value,
			changed: true
		})
	}

	handleCheckbox = e => {
		this.setState({
			[e.target.name]: e.target.checked,
			changed: true
		})
	}

	toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
	handleUpload = async (e) => {
		var files = e.target.files;
		var errors = 0;
		for (var i = 0; i < files.length; i++) {
			var file = files[i];
			var data = await this.toBase64(file);
			try{
				var response = await Api.call('/settings/upload',{
					id: this.props.id,
					img: data
				})
				if (response.success === false)
					errors++;
			}
			catch {
				errors++;
			}
		}
		this.updateSettings();
		if (errors > 0)
			alert(Localization.t('settings_upload_error'));
	}

	saveSettings = () => {

		Api.call('/settings/save',this.state)
		.then(()=>{
			this.updateSettings();
		})
		.catch(()=>{
			alert(Localization.t('error_500'))
		})
		
	}

	updateSettings = () => {
		Api.updatePointer();
		Api.call('/settings')
		.then(response=>{
			if (response.success === true){
				response.data.changed = false;
				response.data.legal_entity = Boolean(response.data.legal_entity);
				this.setState(response.data);
			}
		})
		.catch(()=>{
			alert(Localization.t('error_500'))
		})
	}

	componentDidMount(){
		this.updateSettings();
	}

	/* Connections */

	connectFacebook = () => {
		window.location.href = Api.url + '/facebook/connect';
	}
	disconnectFacebook = () => {
		Api.call('/facebook/disconnect')
		.then(response=>{
			if (response.success === false){
				if (response.code === 1)
					alert(response.status);
				else
					alert(Localization.t('error_500'));
			}
			this.updateSettings();
		});
	}

	/* Render */

	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		return(
			<Container>
				<h1 style={{
					textAlign: 'center'
				}}>{Localization.t('settings_title')}</h1>
				<Row className="editor">
					<Col xs={12} md={4}>
						{
							this.tabs.map(tab=>(
								<Row key={tab.id} className={"btn " + (this.state.group === tab.id ? ' active' : '')} id={tab.id} onClick={this.hangeGroup}>
									{tab.name}
								</Row>
							))
						}
					</Col>
					<Col xs={12} md={8} >
						{/* Obecné informace */}
						{
							this.state.group === 'general' &&
							<form>
								<label htmlFor="upload" style={{
									display: 'block',
									textAlign: 'center'
								}}>
									<img alt="" src={this.state.img} onError={()=>{
										this.setState({
											img: '/img/newUser.png'
										})
									}} className="circle hoverZoomOut" style={{
										width: '15%',
										cursor: 'pointer'
									}} />
								</label>
								<input 
									type="file"
									name="upload"
									id="upload"
									accept="image/*"
									onChange={this.handleUpload}
									style={{
										display: 'none'
									}}
								/>

								<Row>
									<Col xs={12} lg={6}>
										<label>{Localization.t('fname')}:</label>
										<input type="text" name="fname" value={this.state.fname} onChange={this.handleChange} />
									</Col>
									<Col xs={12} lg={6}>
										<label>{Localization.t('lname')}:</label>
										<input type="text" name="lname" value={this.state.lname} onChange={this.handleChange} />
									</Col>
								</Row>

								<label>{Localization.t('username')}</label>
								<input type="text" name="name" value={this.state.name} onChange={this.handleChange} />

								<label>{Localization.t('settings_general_country')}:</label>
								<select name="country" value={this.state.country} onChange={this.handleChange} >
									<option value="cz">Česká republika</option>
									<option value="sk">Slovenská republika</option>
								</select>

								<label>{Localization.t('settings_general_lang')}:</label>
								<select name="lang" value={this.state.lang} onChange={this.handleChange}>
									<option value="cz">Čeština</option>
									<option value="en">English</option>
								</select>

								<label>{Localization.t('settings_general_currency')}:</label>
								<select name="currency" value={this.state.currency} onChange={this.handleChange}>
									<option value="CZK">Česká koruna (CZK)</option>
									<option value="EUR">Euro (EUR)</option>
								</select>

								<label>{Localization.t('settings_general_news')}</label>
								<select name="newsletter" value={this.state.newsletter} onChange={this.handleChange}>
									<option value="1">{Localization.t('settings_general_news_yes')}</option>
									<option value="0">{Localization.t('settings_general_news_no')}</option>
								</select>

								<label>{Localization.t('settings_general_email_notify')}</label>
								<select name="emailsubscribe" value={this.state.emailsubscribe} onChange={this.handleChange}>
									<option value="1">{Localization.t('settings_general_email_notify_yes')}</option>
									<option value="0">{Localization.t('settings_general_email_notify_no')}</option>
								</select>
								<small>{Localization.t('settings_general_email_notify_tip')}</small>
							</form>
						}
						{/* Prodejce */}
						{
							this.state.group === "seller" &&
							<form>
								<Row>
									<Col>
										<label>
											{Localization.t('settings_seller_bank_account')}<br />
											<small style={{
												marginLeft: '10px'
											}}>
												{Localization.t('settings_seller_bank_account_tip')}
											</small>
										</label>
										<input type="text" name="bank_account" value={this.state.bank_account} onChange={this.handleChange} />
									</Col>
									<Col>
										<label>
											{Localization.t('settings_seller_bank_number')}<br />
											<small style={{
												marginLeft: '10px'
											}}>
												{Localization.t('settings_seller_bank_number_tip')}
											</small>
										</label>
										<input type="text" name="bank_number" value={this.state.bank_number} onChange={this.handleChange} />
									</Col>
								</Row>
								


								<label className="checkbox">
									<input type="checkbox" name="legal_entity" id="legal_entity" checked={this.state.legal_entity} onChange={this.handleCheckbox} />
									<span className="checkmark"></span>
								</label>
								<label htmlFor="legal_entity" style={{
									marginLeft: '20px',
									cursor: 'pointer'
								}}>{Localization.t('settings_seller_legal_entity')}</label>
								{
									this.state.legal_entity === true &&
									<div>
										<br />
										<label>{Localization.t('settings_seller_ic')}:</label>
										<input type="text" name="IC" value={this.state.IC} onChange={this.handleChange} />

										<label>{Localization.t('settings_seller_dic')}:</label>
										<input type="text" name="DIC" value={this.state.DIC} onChange={this.handleChange} />
									</div>
								}
							</form>
						}
						{/* Moje data */}
						{
							this.state.group === "gdpr" &&
							<div>
								{/* TODO: Stáhnout moje data */}
								<button className="btn btn-second" onClick={()=>{alert('Funkce není zatím implementována.')}}>Stáhnout moje data</button>
								
								{/* TODO: Smazat účet */}
								<button className="btn" style={{
									background: '#F44336'
								}} onClick={()=>{alert('Funkce není zatím implementována.')}}>Smazat účet</button>
							</div>
						}
						{
							this.state.group === "password" &&
							<div>

								<h3>{Localization.t('settings_pass_title')}</h3>
								{
									this.state.pass &&
									<input placeholder={Localization.t('settings_pass_old')} type="password" name="oldPass" value={this.state.oldPass} onChange={this.handleChange} />
								}
								<input placeholder={Localization.t('settings_pass_new')} type="password" name="newPass" value={this.state.newPass} onChange={this.handleChange} />
								<input placeholder={Localization.t('settings_pass_new_again')} type="password" name="newPassAgin" value={this.state.newPassAgin} onChange={this.handleChange} />
								<button type="button" className="btn" onClick={this.setPassword}>{Localization.t('settings_pass_title')}</button>
							</div>
						}
						{/* Propojení */}
						{
							this.state.group === "connect" &&
							<div>
								<h3>{Localization.t('settings_tab_connection')}</h3>
								<Row>
									<Col xs={12} lg={6}>
										<ConnectionCard name="Facebook" id={this.state.fb_id} icon="/img/connections/facebook.png" disconnect={this.disconnectFacebook} connect={this.connectFacebook} />
									</Col>
								</Row>
							</div>
						}
						<Row>
							<Col xs={8}></Col>
							<Col xs={4}>
								<button className={this.state.changed === true ? 'btn' : 'btn btn-second'} onClick={this.saveSettings}>
									{Localization.t('settings_save')}
								</button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}
}