import React, {Component} from 'react';
import { Container, Row, Col } from 'react-grid-system';
import ImageBox from '../layout/ImageBox';
import Api from '../Api';
import SearchResults from '../components/SearchResults';
import Remap from '../Remap';
import Chip from '../layout/Chip';
import { Link } from 'react-router-dom';
import Like from '../components/Like';
import LoginBox from '../layout/LoginBox';
import CommentSection from '../components/CommentSection';
import Localization from '../Localization';

export default class DetailPage extends Component {

	state = {
		item: {},
		noLoginMessage: false,
		token: null,
		cartModal: false
	}

	componentDidUpdate(prev){
		if (Api._token !== this.state.token)
		{
			this.setState({
				token: Api._token
			});
			this.updateItem();
		}
		if (this.props.match.params.id !== prev.match.params.id)
		{
			this.updateItem();
		}
	}

	componentDidMount(){
		this.updateItem();
		this.googleRemarketing();
	}

	googleRemarketing() {
		window.gtag('event', 'page_view', {
			'send_to': 'AW-969069533',
			'value': 'replace with value',
			'items': [{
			  'id': this.props.match.params.id,
			  'google_business_vertical': 'retail'
			}]
		});
	}

	updateItem = () => {
		window.scrollTo(0, 0);
		this.setState({
			processing: true
		})
		Api.call('/item',{
			id: this.props.match.params.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					item: response.data,
					noLoginMessage: false
				});
			} else if (response.code === 403) {
				Api.loginPointer();
				this.setState({
					noLoginMessage: true
				});
			}
		})
		.catch(err=>{
			console.error(err);
		})
	}

	closeModal = () => {
		this.setState({
			cartModal: false
		});
	}
	openModal = () => {
		this.setState({
			cartModal: true
		});
	}

	addToCart = () => {
		if (Api._token === undefined || Api._token === null){
			Api.loginPointer();
			return;
		}

		Api.call('/cart/add',{
			id: this.state.item.ID
		})
		.then(response=>{
			this.updateItem();
			this.openModal();
		})
		.catch(err=>{
			console.error(err);
		})
	}
	removeFromCart = () => {
		Api.call('/cart/remove',{
			id: this.state.item.ID
		})
		.then(response=>{
			this.updateItem();
		})
		.catch(err=>{
			console.error(err);
		})
	}

	render(){
		if (this.state.noLoginMessage === true)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		return(
			<div className="page DetailPage">
				<Container>
					{
						this.state.cartModal === true &&
						<LoginBox close={this.closeModal}>
							<h1>{Localization.t('cart_dialog_title')}</h1>
							<Row>
								<Col xs={3}>
									<img className="full" alt="" src={this.state.item.imgs[0]} />
								</Col>
								<Col xs={9}>
									<b>{this.state.item.name} - {this.state.item.brand}</b>
									<p>{this.state.item.price + ' ' + this.state.item.currency}</p>
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<button className="btn btn-second" onClick={this.closeModal}>{Localization.t('cart_dialog_continue')}</button>
								</Col>
								<Col xs={6}>
									<Link to="/cart">
										<button  className="btn">{Localization.t('cart_dialog_checkout')}</button>
									</Link>
								</Col>
							</Row>
						</LoginBox>
					}
					<Row>
						<Col sm={12} lg={7}>
							<ImageBox images={this.state.item.imgs ?? []}/>
						</Col>
						<Col sm={12} lg={5}>
							
							<Like type="itm" id={this.state.item.ID} />
							<h1>{this.state.item.brand ?? ''}</h1>
							<h2>{this.state.item.name ?? ''}</h2>

							{/* Přidat do košíku */}

							<p style={{
								fontSize: '2.5em'
							}}>{Remap.price(this.state.item.price ?? 0)} {Remap.currency(this.state.item.currency ?? '')}</p>

							{
								!this.state.item.inCart &&
								<button className="btn" onClick={this.addToCart}>{Localization.t('cart_button_add')}</button>
							}
							{
								this.state.item.processing === true &&
								<button className="btn">...</button>
							}
							{
								this.state.item.inCart === 1 &&
								<button className="btn" onClick={this.removeFromCart}>{Localization.t('cart_button_remove')}</button>
							}
							

							{/* Velikost */}
							{
								(this.state.item.size ?? '') !== '' &&
								<p>
									<b>{Localization.t('item_prop_size')}:</b>&nbsp;
									{this.state.item.size}
								</p>
							}

							{/* Popis */}

							<h3>{Localization.t('item_prop_desc')}</h3>
							<p>
								{
									(this.state.item.description ?? '').split('\n').map((item,key)=>(
										<span key={key}>
											{item}
											<br />
										</span>
									))
								}
							</p>


							{/* Stav zboží */}
							
							<h3>{Localization.t('item_prop_state')}</h3>
							{
								Localization.t('item_prop_state_'+this.state.item.condition)
							}
							
							{/* Prodejce */}
							<h3>{Localization.t('item_prop_seller')}</h3>
							<Link to={'/user/'+(this.state.item.seller ?? {}).ID} style={{
								textDecoration: 'none',
								color: 'black'
							}}>
								<Row>
									<Col xs={2}>
										<img src={(this.state.item.seller ?? {img:'/img/newUser.png'}).img} alt={(this.state.item.seller ?? {}).name + "'s icon on IloveLV"} className="full circle" />
									</Col>
									<Col xs={10}>
										<p>
											{(this.state.item.seller ?? {}).name}
										</p>
										{
											(this.state.item.seller ?? {}).expert === 1 &&
											<Chip big="true" img="/img/icon/guarantee.png" type="expert">
												Expert seller
											</Chip>
										}
										{
											((this.state.item.seller ?? {}).verified === 1 && (this.state.item.seller ?? {}).expert === 0) &&
											<Chip big="true" img="/img/icon/guarantee.png" type="trusted">
												Trusted seller
											</Chip>
										}
									</Col>
								</Row>
							</Link>

							{/* Doručení */}
							<small>{this.state.item.directShipping ? Localization.t('shiptime_short') : Localization.t('shiptime_long')}</small>

						</Col>
					</Row>
				</Container>
				<br />
				<div style={{
					
				}}>
					<Container>
						<Row>
							<Col xs={12}>
								<h1>{Localization.t('comment_title')}</h1>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<CommentSection id={this.state.item.ID} type="itm" allowReply={true} />
								<br />
							</Col>
						</Row>
					</Container>
				</div>
				<div style={{
					background: '#eee'
				}}>
					<Container>
						<Row>
							<Col xs={12}>
								<h1>{Localization.t('item_similar')}</h1>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<SearchResults category={this.state.item.category ?? undefined} />
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}