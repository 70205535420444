import React, {Component} from 'react';
import Api from './../Api';
import {Link} from 'react-router-dom';
import './HeaderQuickBar.css';
import Dropdown from './Dropdown';
import Cart from './../components/Cart';
import Notification from './Notification';
import Localization from '../Localization';

export default class HeaderQuickBar extends Component {

	state = {
		notifications: []
	}

	componentDidMount() {
		this.updateNotifications();
		setInterval(()=>{
			this.updateNotifications();
		},10000);
	}

	updateNotifications = () => {
		Api.call('/notifications')
		.then(response=>{
			if (response.success === true){
				this.setState({
					notifications: response.data
				});
			}
		})
		.catch(()=>{})
	}
	notifyReadAll = () => {
		Api.call('/notifications/readAll')
		.then(()=>{
			this.updateNotifications();
		})
	}
	notifyRead = (id) => {
		Api.call('/notifications/read',{id: id})
		.then(()=>{
			this.updateNotifications();
		})
	}


	render(){
		var newNotify = this.state.notifications.filter(n=>n.readed===0).length > 0;
		return(
			<div className="quickbar">
				{
					(this.props.user === null ||
					this.props.user === undefined) &&
					<div>
						<p className="hoverColor" onClick={Api.registerPointer} style={{
							transform: 'translateY(50%)',
							cursor: 'pointer',
							display: 'inline-block',
							float: 'right',
							marginLeft: '25px'
						}}>{Localization.t('menu_register')}</p>
						<p className="hoverColor" onClick={Api.loginPointer} style={{
							transform: 'translateY(50%)',
							cursor: 'pointer',
							display: 'inline-block',
							float: 'right',
							marginLeft: '25px'
						}}>{Localization.t('menu_login')}</p>
						<Link to="/sell">
							<p className="hoverColor" style={{
								transform: 'translateY(50%)',
								cursor: 'pointer',
								display: 'inline-block',
								float: 'right',
								background: '#ff4242',
								color: 'white',
								boxShadow: '0px 0px 0 5px #ff4242',
								borderRadius: '3px'
							}}>{Localization.t('menu_sell')}</p>
						</Link>
					</div>
				}
				{
					this.props.user !== null &&
					this.props.user !== undefined &&
					<div>
						<Link to="/cart">
							<div className="dropdown" >
								<img 
									src="/img/icon/cart.png" 
									alt=""
									style={{
										height: '2.1em',
										transform: 'translateY(50%)',
										cursor: 'pointer',
										marginLeft: '15px'
									}}
								/>
								{/* TODO: Počet položek v košíku */}
								<Dropdown>
									<Cart user={this.props.user} small={true} />
								</Dropdown>
							</div>
						</Link>
						<div className="dropdown">
							<img 
								src={'/img/icon/bell'+(newNotify ? '_fill' : '')+'.svg'}
								className={newNotify ? 'shaking' : ''}
								alt=""
								style={{
									height: '2.1em',
									transform: 'translateY(50%)',
									cursor: 'pointer',
									marginLeft: '15px'
									}}
								onClick={this.notifyReadAll}
							/>
							<Dropdown>
								{
									this.state.notifications.length === 0 &&
									<Notification icon="/img/notify/welcome.png" title={Localization.t('notify_welcome_title')} text={Localization.t('notify_welcome_content')} />
								}
								{
									this.state.notifications.length > 0 && this.state.notifications[0].readed === 0 &&
									<p style={{
										paddingTop: '0px',
										margin: '5px'
									}}>{Localization.t('notify_new')}</p>
								}
								{
									this.state.notifications.map((notify,key) => (
										<div key={key}>
											{
												notify.readed === 1 && key !== 0 && this.state.notifications[key-1].readed === 0 &&
												<p style={{
													borderTop: '1px solid #EAEAEA',
													paddingTop: '5px',
													margin: '5px'
												}}>{Localization.t('notify_old')}</p>
											}
											<Notification icon={notify.img} title={notify.title} text={notify.text} link={notify.link} onClick={()=>{this.notifyRead(notify.ID)}} />
										</div>
									))
								}
								
							</Dropdown>
						</div>
										
						<div className="dropdown">
							<img 
								src={this.props.user.img}
								alt={this.props.user.name + " profile image"}
								style={{
									height: '2.1em',
									transform: 'translateY(50%)',
									cursor: 'pointer',
									borderRadius: '50%',
									marginLeft: '15px'
								}}
						/>
							<Dropdown>
								<Link to="/editor" className="btn">
									{Localization.t('menu_my_items')}
								</Link>
								<Link to={'/user/'+this.props.user.ID} className="btn">
									{Localization.t('menu_my_profile')}
								</Link>
								<Link to="/settings" className="btn">
									{Localization.t('menu_settings')}
								</Link>
								{
									this.props.user.admin === 1 &&
									<a className="btn" href="/admin">
										{Localization.t('menu_admin')}
									</a>
								}
								<div onClick={Api.logoutPointer} className="btn">
									{Localization.t('menu_logout')}
								</div>
							</Dropdown>
						</div>

						<Link to="/sell" style={{
							color: 'black'
						}}>
							<p className="hoverColor" style={{
								transform: 'translateY(50%)',
								cursor: 'pointer',
								display: 'inline-block',
								marginRight: '10px',
								float: 'right',
								background: '#ff4242',
								color: 'white',
								boxShadow: '0px 0px 0 5px #ff4242',
								borderRadius: '3px'
							}}>{Localization.t('menu_sell')}</p>
						</Link>
					</div>
				}
			</div>
		)
	}
}