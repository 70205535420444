import React, {Component} from 'react';
import Api from '../../Api';
import { Row, Col } from 'react-grid-system';
import Localization from '../../Localization';
export default class EditorImages extends Component {

	state = {
		images: [],
		loading: false
	}

	setUploadAs = null;

	componentDidMount(){
		this.updateImages();
	}

	updateImages = () => {
		Api.call('/item/images',{
			id: this.props.id
		}).then(response=>{
			if (response.success === true){
				this.setState({
					images: response.data
				})
			}
		});
	}

	setImgType = (file,type) => {
		Api.call('/edit/imgtype',{
			id: this.props.id,
			file: file,
			type: type
		})
		.then(()=>{
			this.updateImages();
		})
		.catch(()=>{
			this.updateImages();
		})
	}

	delImg = (file) => {
		if (window.confirm(Localization.t('editor_image_delete_confirm')))
		{
			Api.call('/edit/imgdelete',{
				id: this.props.id,
				file: file
			})
			.then(()=>{
				this.updateImages();
			})
			.catch(()=>{
				this.updateImages();
			})
		}
	}

	toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	handleUpload = async (e) => {
		this.setState({
			loading: true
		});

		var files = e.target.files;
		var errors = 0;
		for (var i = 0; i < files.length; i++) {
			var file = files[i];
			var data = await this.toBase64(file);
			try{
				var response = await Api.call('/edit/upload',{
					id: this.props.id,
					img: data
				})
				if (response.success === false)
					errors++;

				if (this.setUploadAs != null){
					this.setImgType(response.data,this.setUploadAs);
					this.setUploadAs = null;
				}
					
			}
			catch {
				errors++;
			}
		}
		this.updateImages();
		this.setState({
			loading: false
		})
		if (errors > 0)
			alert(Localization.t('editor_image_upload_error') + errors+"/" + files.length);

		return true;
	}

	runUpload = (as = null) =>
	{
		this.setUploadAs = as;
		var upload = document.getElementById('upload');
		if (upload != null)
			upload.click();
		
		return true;
	}

	render(){
		return(
			<div>
				{
					this.state.loading === true &&
					<div style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255,255,255,0.7)',
						top: '0px',
						left: '0px',
						zIndex: 5
					}}>
						<p style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%,-100%)',
							fontSize: '2em',
							fontWeight: 'bold'
						}}>{Localization.t('editor_image_loading')}</p>
					</div>
				}
				<h3>{Localization.t('editor_image_group_required')}</h3>
				<div className="imgedit">
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Přední strana" src={((this.state.images[0] ?? {}).front  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('front')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_front')}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Zadní strana" src={((this.state.images[0] ?? {}).back  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('back')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_back')}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Boční strana" src={((this.state.images[0] ?? {}).side  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('side')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_side')}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Spodní strana" src={((this.state.images[0] ?? {}).bottom  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('bottom')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_bottom')}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Logo" src={((this.state.images[0] ?? {}).logo  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('logo')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_logo')}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							<img style={{cursor: 'pointer'}} alt="Data kód" src={((this.state.images[0] ?? {}).code  ?? {url: '/img/add.png'}).url} className="full" onClick={()=>{this.runUpload('code')}} />
						</Col>
						<Col xs={9}>
							<h3>{Localization.t('editor_image_special_code')}</h3>
						</Col>
					</Row>
				</div>

				<h3>{Localization.t('editor_image_group_other')}</h3>
				<Row>
					{
						this.state.images.map(img=>{
							if (img !== this.state.images[0] && !Object.values(this.state.images[0]).map(x=>x.filename).includes(img.filename))
							return (
								<Col key={img.filename} xs={3} style={{
									marginTop: '10px'
								}} className="hoverDelete">
									<img src={img.url} alt={"I love LV - " + img.filename} style={{
										maxWidth: '100%',
										border: img.main === true ? '3px solid #a16d5d' : '3px solid transparent',
										borderRadius: '5px'
									}} />
									<div>
										<div style={{
											display: 'inline-block',
											width: '100%'
										}}>
											{
												(this.props.item ?? {}).draft === 1 &&
												<button onClick={()=>{this.delImg(img.filename)}} className="delete" style={{
													width: '100%',
													backgroundColor: '#F44336',
													border: 'none',
													padding: '5px 0px',
													color: 'white',
													cursor: 'pointer'
												}}>{Localization.t('editor_image_delete')}</button>
											}
											
										</div>
									</div>
								</Col>
							);
							else
							return(<div />)
						})
					}
					{
						(this.props.item ?? {}).draft === 1 &&
						<Col xs={3} style={{
							marginTop: '10px'
						}}>
							<input
								type="file"
								name="upload"
								id="upload"
								multiple="multiple"
								accept=".jpeg, .jpg, .png"
								onChange={this.handleUpload}
								style={{
									display: 'none'
								}}
							/>
							<div style={{
								position: 'relative',
								padding: '50%'
							}}>
								<label for="upload" title="Přidat obrázek" style={{
									position: 'absolute',
									display: 'flex',
									top: '0px',
									left: '0px',
									right: '0px',
									bottom: '0px',
									textAlign: 'center',
									background: '#ccc',
									flexDirection: 'column',
									justifyContent: 'center',
									cursor: 'pointer',
									fontSize: '5rem',
									color: '#888'
								}}>
									+
							</label>
							</div>
						</Col>
					}
					
				</Row>
				{
					(this.props.item ?? {}).draft === 1 &&
					<p>
						{
							this.state.images.length > 13 &&
							<b style={{color: 'red'}}>{this.state.images.length - 1} z 12 fotek</b>
						}
						{
							this.state.images.length <= 13 && this.state.images.length > 1 &&
							<b style={{color: 'green'}}>{this.state.images.length - 1} z 12 fotek</b>
						}
					</p>
				}
			</div>
		);
	}
}