import React, {Component} from 'react';
import { Container } from 'react-grid-system';
import Cart from '../components/Cart';
import Api from '../Api';
import Localization from '../Localization';

export default class CartPage extends Component {
	render(){
		if (Api._token === undefined || Api._token === null)
			return(
				<Container>
					<span className="error">{Localization.t('login_required')}</span>
				</Container>
			);
		return(
			<Container>
				<Cart user={this.props.user} />
			</Container>
		);
	}
}