import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Localization from '../Localization';
import Api from './../Api';
export default class DispatchPage extends Component {
	
	state = {
		item: null,
		traceCode: ""
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	componentDidMount(){
		this.updateItem();
	}

	updateItem(){
		Api.call('/item/dispatchdata',{
			id: this.props.match.params.id
		})
		.then(response=>{
			if (response.success === true){
				this.setState({
					item: response.data
				})
			}
		});
	}

	submit = (e) => {
		e.preventDefault();
		var conf = window.confirm(Localization.t('dispatch_confirm'));
		if (conf){
			Api.call('/item/dispatch',{
				id: this.props.match.params.id,
				traceCode: this.state.traceCode
			}).then(response=>{
				if (response.success === true)
					window.location.href = "/order/"+this.state.item.orderID;
			})
		}
	}

	render(){
		if (this.state.item === null)
			return(
				<Container>
					<span className="error">Nothing is here</span>
				</Container>
			);
		else
			return(
				<Container>
					<h1>{Localization.t('dispatch_title')}</h1>
					<Row>
						<Col>
							<h2>{this.state.item.name}</h2>
							<p>{this.state.item.description}</p>
							<p><b>{this.state.item.price} {this.state.item.currency}</b></p>
							<small>{this.state.item.tags}</small>
							<hr />
							<h2>{Localization.t('dispatch_submit')}</h2>
							<form onSubmit={this.submit}>
								<input type="text" name="traceCode" placeholder="Sledovací číslo (Pokud máte, není povinné)" onChange={this.handleChange} value={this.state.traceCode} />
								<input type="submit" className="btn" value="Odeslal jsem zboží" />
							</form>
						</Col>
						<Col>
							<h2>{Localization.t('dispatch_help_title')}</h2>
							<p>{Localization.t('dispatch_help_line1')}</p>
							<p>{Localization.t('dispatch_help_line2')}</p>
							
							<div style={{
								background: '#eaeaea',
								padding: '10px',
								border: '1px solid #888'
							}}>
								<b>{Localization.t('dispatch_prop_packID')}:</b> i{this.state.item.ID}o{this.state.item.orderID}<br />
								<b>{Localization.t('dispatch_prop_addr')}:</b><br />
								ILOVELV<br />
								Baranova 35<br />
								130 00 Praha 3<br />
								Czech Republic
							</div>

						</Col>
					</Row>
				</Container>
			);
	}
}