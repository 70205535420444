import React, {Component} from 'react';
import './ImageBox.css';
import ImgMagify from './ImgMagnify';

export default class ImageBox extends Component {

	state = {
		selectedIndex: 0
	}

	prev = () => {
		if (this.state.selectedIndex > 0){
			this.setState({
				selectedIndex: this.state.selectedIndex - 1
			});
		}
	}
	next = () => {
		if (this.state.selectedIndex + 1 < this.props.images.length){
			this.setState({
				selectedIndex: this.state.selectedIndex + 1
			});
		}
	}

	render(){
		return(
			<div className="imageBox">
				<div className="imgContainer">
					<div className="imgWrap">
						<ImgMagify src={this.props.images[this.state.selectedIndex]} alt="" />
						{
							this.state.selectedIndex > 0 &&
							<button className="left" onClick={this.prev} />
						}
						{
							this.state.selectedIndex + 1 < this.props.images.length &&
							<button className="right" onClick={this.next} />
						}
					</div>
				</div>
				<div className="imgLine" ref="line">
					{
						(this.props.images ?? []).map((image,key)=>{
							return(
								<img src={image} alt="" key={key} className={this.state.selectedIndex === key ? 'selected' : ''} onClick={()=>{
									this.setState({
										selectedIndex: key
									});
								}} />
							);
						})
					}
				</div>
			</div>
		);
	}
}