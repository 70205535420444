import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './ImgSlider.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default class ImgSlider extends Component {

	state = {
		index: 0,
		hover: false
	}

	componentDidMount(){
		setInterval(()=>{
			this.autoNext();
		}, 5000);
	}

	hoverStart = () => {
		this.setState({
			hover: true
		});
	}
	hoverStop = () => {
		this.setState({
			hover: false
		});
	}

	autoNext = () => {
		if (!this.state.hover)
			this.next();
	}

	next = (e) => {
		if (e !== undefined)
			e.stopPropagation();
		this.set(this.state.index + 1);
	}

	prev = (e) => {
		if (e !== undefined)
			e.stopPropagation();
		this.set(this.state.index - 1);
	}

	set = (index) => {
		if (index >= this.props.imgs.length)
			index = 0;
		if (index < 0)
			index = this.props.imgs.length - 1;
		this.setState({
			index: index
		});
	}

	render(){
		return(
			<div className="ImgSlider" onMouseEnter={this.hoverStart} onMouseLeave={this.hoverStop}>
				<img src={this.props.imgs[this.state.index]} className="full" alt="" />
				<div className="slides">
					<TransitionGroup>
					{
						this.props.imgs.map((img, key)=>{
							if (key === this.state.index)
								return(
									<CSSTransition key={key} timeout={700} classNames="slide">
										<img src={img} className="full slide" alt="" />
									</CSSTransition>
								);
							else 
								return null;
						})
					}
					</TransitionGroup>
				</div>
				<div className="controls" onClick={this.onClick}>
					<button className="arrow left" onClick={this.prev} />
					<button className="arrow right" onClick = {this.next} />
					<div className="dots">
						{
							this.props.imgs.map((img,key)=>(
								<button key={key} onClick={(e)=>{e.stopPropagation();this.set(key)}} title={key + 1} className={this.state.index === key ? "active" : ""} />
							))
						}
					</div>
				</div>
			</div>
		);
	}

	onClick = () =>{
		window.location.href = "/sell";
	}
}

ImgSlider.prop = {
	imgs: PropTypes.array.isRequired
}