import React from 'react';
import './LoginBox.css';
//import { Row, Col } from 'react-grid-system';

function LoginBox(props){
	return(
		<div className="loginBox" onClick={props.close}>
			<div className="wrap" onClick={(e)=>{e.stopPropagation()}}>
				{props.children}
				{
					/*
					<div className="line">
						<div>
							<p>NEBO</p>
						</div>
					</div>
					<Row>
						<Col xs={12} lg={6}>
							<button className="socialLogin facebook">
								{props.textFB ?? 'Přihlásit se přes Facebook'}
							</button>
						</Col>
						<Col xs={12} lg={6}>
							<button className="socialLogin google">
								{props.textGoogle ?? 'Přihlásit se přes Google'}
							</button>
						</Col>
					</Row>
					*/
				}
			</div>
		</div>
	);
}
export default LoginBox;