import React, {Component} from 'react';
import InputPrice from '../../components/InputPrice';
import Localization from '../../Localization';

export default class EditorPrice extends Component {

	priceHandler = (price) => {
		this.props.handler({
			target: {
				name: 'price',
				value: price
			}
		})
	}

	render(){
		return(
			<div>
				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_price')}</label>
				<InputPrice onChange={this.priceHandler} value={(this.props.item ?? []).price} currency={(this.props.item ?? []).currency} />
				
				<label style={{
					paddingLeft: '10px',
					marginTop: '10px',
					display: 'block'
				}}>{Localization.t('editor_currency')}</label>
				<select defaultValue={(this.props.item ?? []).currency} name="currency" onChange={this.props.handler}>
					<option disabled value="-1">{Localization.t('editor_currency_placeholder')}</option>
					<option value="czk">Česká koruna (CZK)</option>
					<option value="eur">Euro (EUR)</option>
				</select>
			</div>
		);
	}
}