import React, { Component } from 'react';
import { Container, Row, Col, Hidden, /*Hidden*/ } from 'react-grid-system';
import AdvantageBox from '../layout/AdvantageBox';
import SearchResults from '../components/SearchResults';
import Api from '../Api';
import ImgSlider from '../layout/ImgSlider';
import Localization from '../Localization';
//import UserWidget from '../layout/UserWidget';

export default class HomePage extends Component {

	state = {
		orderBy: 'date',
		desc: true,

		banners: [],
		topDesigners: [],
		benefits: []
	}

	componentDidMount(){
		this.updateBanners();
		this.updateBenefits();
		this.updateTopDesigners();
	}

	updateBanners = () => {
		Api.call('/campaign/banner')
		.then(response=>{
			if (response.success === true){
				this.setState({
					banners: response.data
				});
			}
		});
	}

	updateBenefits = () => {
		Api.call('/campaign/benefits')
		.then(response=>{
			if (response.success === true){
				this.setState({
					benefits: response.data
				})
			}
		})
	}

	updateTopDesigners = () => {
		Api.call('/campaign/designers')
		.then(response=>{
			if (response.success === true){
				this.setState({
					topDesigners: response.data
				})
			}
		})
	}

	render(){
		return(
			<div className="page homePage">
				<Hidden xs sm>
					<Container style={{
						marginBottom: '20px'
					}}>
						<Row>
							{
								this.state.benefits.map((item,key)=>(
									<Col xs={3} key={key}>
										<AdvantageBox text={item.text} img={item.img} />
									</Col>
								))
							}
						</Row>
					</Container>
				</Hidden>
				<Container>
					<ImgSlider imgs={this.state.banners} />
				</Container>
				
				<Hidden xs sm>
					<Container>
						<h2>{Localization.t('home_top_designers')}</h2>
						<Row>
							{
								this.state.topDesigners.map((designer,key)=>(
									<Col key={key} xs={3} style={{
										padding: '1px'
									}}>
										<a href={designer.url} style={{
											textDecoration: 'none',
											color: 'black',
											position: 'relative',
											display: 'block'
										}}>
											<img className="full" src={designer.img} alt="" />
											<label style={{
												position: 'absolute',
												left: '1em',
												bottom: '1em'
											}}>{designer.text ?? ''}</label>
										</a>
									</Col>
								))
							}
							
						</Row>
					</Container>
				</Hidden>
				{/*<Container>
					<Row>
						<Col xs={12}>
							<h1>Populární prodávající</h1>
						</Col>
					</Row>
					<Row>
						<Col md={2} xs={3}>
							<UserWidget />
						</Col>
						<Col md={2} xs={3}>
							<UserWidget />
						</Col>
						<Col md={2} xs={3}>
							<UserWidget />
						</Col>
						<Col md={2} xs={3}>
							<UserWidget />
						</Col>
						<Hidden xs sm>
							<Col md={2} xs={3}>
								<UserWidget />
							</Col>
							<Col md={2} xs={3}>
								<UserWidget />
							</Col>
						</Hidden>
					</Row>
				</Container>*/}
				<div  style={{
					backgroundColor: /*'#fafafa'*/'#eee'
				}}>
					<Container>
						<Row>
							<Col xs={12}>
								<h1>{Localization.t('home_new_items')}</h1>
							</Col>
						</Row>
						<SearchResults orderBy="date" desc={true}/>
					</Container>
				</div>
			</div>
		);
	}
	
}